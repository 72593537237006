/* eslint-disable unicorn/prefer-module */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import cx from "classix";
import { Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos } from "../../utils";
import "./stylesCategoriaSmartPhones.scss";

export const SmartPhones = ({ forceSubmit, edificiosPreseleccionados }) => {
  const [edificios, setEdificios] = useState([]);
  const [checkAll, setCheckAll] = useState(true);
  const [checkedBuildings, setCheckedBuildings] = useState({});
  const [totalIndividuosSeleccionados, setTotalIndividuosSeleccionados] = useState(0);
  const [cantidadEdificiosSeleccionados, setCantidadEdificiosSeleccionados] = useState(0);
  const [porcentajeGamaMedia, setPorcentajeGamaMedia] = useState(70);
  const [recambioGamaMedia, setRecambioGamaMedia] = useState(3);
  const [recambioGamaAlta, setRecambioGamaAlta] = useState(2);
  const [precioGamaMedia, setPrecioGamaMedia] = useState(500);
  const [precioGamaAlta, setPrecioGamaAlta] = useState(1200);
  const { pais } = useAuthContext();

  const localString = (pais) =>
    ({
      Chile: "es-CL",
      Perú: "es-PE",
      Uruguay: "es-UY",
    })[pais] || "es-UY";

  const urlsPorPais = {
    Chile: "https://www.wecast.cl/listadoempresas",
    Perú: "https://visiona.pe/listadoempresas",
    Uruguay: "https://screenmedia.com.uy",
  };

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  const urlListadoEmpresas = urlsPorPais[pais];

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      setEdificios(newEdificios.sort((a, b) => a.edificio.localeCompare(b.edificio)));

      const initialCheckedBuildings = {};
      newEdificios.forEach(({ id }) => {
        initialCheckedBuildings[id] = true;
      });
      setCheckedBuildings(initialCheckedBuildings);
      setCantidadEdificiosSeleccionados(newEdificios.length);

      const totalIndividuos = newEdificios.reduce((acc, { personas }) => acc + (personas || 0), 0);
      setTotalIndividuosSeleccionados(totalIndividuos);
    };

    initialize();
  }, [pais]);

  useEffect(() => {
    const calcularEstadisticasSeleccionadas = () => {
      let cantidadIndividuos = 0;
      let cantidadEdificios = 0;

      edificios.forEach(({ id, personas }) => {
        if (checkedBuildings[id]) {
          cantidadEdificios++;
          cantidadIndividuos += personas || 0;
        }
      });

      setCantidadEdificiosSeleccionados(cantidadEdificios);
      setTotalIndividuosSeleccionados(cantidadIndividuos);
    };

    if (edificios.length > 0) calcularEstadisticasSeleccionadas();
  }, [checkedBuildings, edificios]);

  const toggleCheckAll = useCallback(() => {
    if (!checkAll) {
      const allChecked = {};
      edificios.forEach(({ id }) => {
        allChecked[id] = true;
      });
      setCheckedBuildings(allChecked);
    } else {
      setCheckedBuildings({});
    }
    setCheckAll((prev) => !prev);
  }, [checkAll, edificios]);

  const toggleCheck = useCallback(
    (id) => {
      setCheckedBuildings((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
      setCheckAll((prev) => {
        const allSelected = edificios.every((edificio) => prev[edificio.id] || edificio.id === id);
        return allSelected;
      });
    },
    [edificios]
  );

  const calcularGamaAutos = (totalEstacionamientos) => {
    const gamaMedia = Math.round(totalEstacionamientos * (porcentajeGamaMedia / 100));
    const gamaAlta = Math.round(totalEstacionamientos * ((100 - porcentajeGamaMedia) / 100));
    return { gamaAlta, gamaMedia };
  };

  const { gamaAlta, gamaMedia } = calcularGamaAutos(totalIndividuosSeleccionados);

  if (edificios.length === 0) return <Loader />;

  const cantidadGamaMedia = gamaMedia / recambioGamaMedia;
  const cantidadGamaAlta = gamaAlta / recambioGamaAlta;
  const cantidadGamaMediaMensual = cantidadGamaMedia / 12;
  const cantidadGamaAltaMensual = cantidadGamaAlta / 12;
  const cantidadPersonasTotal = cantidadGamaMediaMensual + cantidadGamaAltaMensual;
  const mercadoGamaMedia = cantidadGamaMedia * precioGamaMedia;
  const mercadoGamaAlta = cantidadGamaAlta * precioGamaAlta;
  const mercadoGamaTotal = mercadoGamaMedia + mercadoGamaAlta;

  const preciosGamaMedia = [300, 400, 500, 600];
  const preciosGamaAlta = [1000, 1200, 1400, 1600];

  return (
    <div className="smartphones-table">
      <div className="logo-container">
        <img src={logo} alt={`${pais} Logo`} className="logo" />
      </div>
      <h2 style={{ textAlign: "center", marginBottom: "2rem" }}> Smartphones</h2>
      <div style={{ textAlign: "left", maxWidth: "70%", margin: "0 auto", marginTop: "1rem" }}>
        <p>Por favor:</p>
        <ol>
          <li>SELECCIONA EL INVENTARIO (todos los edificios son seleccionados por defecto)</li>
          <li>DEFINIR:</li>
          <ul>
            <li>PORCENTAJE GAMA MEDIA (el resto serán gama alta)</li>
            <li>HÁBITO DE RECAMBIO (¿cada cuántos años renuevan el celular?)</li>
            <li>PRECIO PROMEDIO SEGÚN CATEGORÍA</li>
          </ul>
        </ol>
      </div>
      <hr style={{ width: "80%", margin: "0 auto", marginTop: "1rem", marginBottom: "1rem" }} />
      <div style={{ maxWidth: "40%", margin: "0 auto" }}>
        <table className="table table-bordered table-hover mx-auto">
          <tbody>
            <tr>
              <td style={{ textAlign: "left" }}>CANTIDAD EDIFICIOS:</td>
              <td style={{ textAlign: "right" }}>{cantidadEdificiosSeleccionados}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>CANTIDAD DE INDIVIDUOS:</td>
              <td style={{ textAlign: "right" }}>
                {totalIndividuosSeleccionados.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>ASIGNAR % CATEGORÍA GAMA MEDIA:</td>
              <td style={{ textAlign: "right" }}>
                <div className="d-flex justify-content-end">
                  <select
                    id="selectGamaMedia"
                    value={porcentajeGamaMedia}
                    onChange={(e) => setPorcentajeGamaMedia(Number(e.target.value))}
                    className="form-select form-select-sm w-auto"
                    style={{ maxWidth: "120px", textAlign: "center", textAlignLast: "center" }}
                  >
                    <option value={30}>30%</option>
                    <option value={40}>40%</option>
                    <option value={50}>50%</option>
                    <option value={60}>60%</option>
                    <option value={70}>70%</option>
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr style={{ width: "80%", margin: "0 auto", marginTop: "1rem", marginBottom: "1rem" }} />
      <div className="table-container">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th width="50%" scope="col" className="text-start">
                CONCEPTO
              </th>
              <th width="25%" scope="col" className="text-center">
                GAMA MEDIA
              </th>
              <th width="25%" scope="col" className="text-center">
                GAMA ALTA
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="text-start">CANTIDAD SMARTPHONES</th>
              <th>
                {gamaMedia.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
              <th className="text-center">
                {gamaAlta.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
            </tr>
            <tr>
              <th className="text-start">RECAMBIO: HÁBITO PROMEDIO</th>
              <th className="text-center">
                <div className="d-flex justify-content-center">
                  <select
                    id="selectRecambioGamaMedia"
                    value={recambioGamaMedia}
                    onChange={(e) => setRecambioGamaMedia(Number(e.target.value))}
                    className="form-select form-select-sm w-auto"
                    style={{ maxWidth: "120px", textAlign: "center", textAlignLast: "center" }}
                  >
                    <option value={1}>1 AÑO</option>
                    <option value={2}>2 AÑOS</option>
                    <option value={3}>3 AÑOS</option>
                    <option value={4}>4 AÑOS</option>
                  </select>
                </div>
              </th>
              <th className="text-center">
                <div className="d-flex justify-content-center">
                  <select
                    id="selectRecambioGamaAlta"
                    value={recambioGamaAlta}
                    onChange={(e) => setRecambioGamaAlta(Number(e.target.value))}
                    className="form-select form-select-sm w-auto"
                    style={{ maxWidth: "120px", textAlign: "center", textAlignLast: "center" }}
                  >
                    <option value={1}>1 AÑO</option>
                    <option value={2}>2 AÑOS</option>
                    <option value={3}>3 AÑOS</option>
                  </select>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-start">RECAMBIO: CANTIDAD SMARTPHONES x AÑO</th>
              <th className="text-center">
                {cantidadGamaMedia.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
              <th className="text-center">
                {cantidadGamaAlta.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
            </tr>
            <tr>
              <th className="text-start">PRECIO: PROMEDIO MERCADO SEGÚN CATEGORÍA</th>
              <th className="text-center">
                <div className="d-flex justify-content-center">
                  <select
                    id="selectPrecioGamaMedia"
                    value={precioGamaMedia}
                    onChange={(e) => setPrecioGamaMedia(Number(e.target.value))}
                    className="form-select form-select-sm w-auto"
                    style={{ maxWidth: "120px", textAlign: "center", textAlignLast: "center" }}
                  >
                    {preciosGamaMedia.map((precio) => (
                      <option key={precio} value={precio}>
                        US${" "}
                        {precio.toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th className="text-center">
                <div className="d-flex justify-content-center">
                  <select
                    id="selectPrecioGamaAlta"
                    value={precioGamaAlta}
                    onChange={(e) => setPrecioGamaAlta(Number(e.target.value))}
                    className="form-select form-select-sm w-auto"
                    style={{ maxWidth: "120px", textAlign: "center", textAlignLast: "center" }}
                  >
                    {preciosGamaAlta.map((precio) => (
                      <option key={precio} value={precio}>
                        US${" "}
                        {precio.toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-start">MERCADO POR CATEGORÍA ANUAL</th>
              <th className="text-center">
                US${" "}
                {mercadoGamaMedia.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
              <th className="text-center">
                US${" "}
                {mercadoGamaAlta.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
            </tr>
            <tr>
              <th className="text-start">MERCADO POR CATEGORÍA MENSUAL</th>
              <th className="text-center">
                US${" "}
                {(mercadoGamaMedia / 12).toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
              <th className="text-center">
                US${" "}
                {(mercadoGamaAlta / 12).toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
            </tr>
            <tr>
              <th className="text-start bg-danger text-white">MERCADO ANUAL TOTAL</th>
              <th className="text-center bg-danger text-white" colSpan={2}>
                US${" "}
                {mercadoGamaTotal.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <hr style={{ width: "80%", margin: "0 auto", marginTop: "1rem", marginBottom: "1rem" }} />

      <div style={{ fontSize: "1.3rem", marginTop: "1.5rem", marginBottom: "1.5rem" }}>
        <div style={{ textAlign: "center", maxWidth: "80%", margin: "0 auto", marginBottom: "1.5rem" }}>
          <p>EN LOS EDIFICIOS SELECCIONADOS;</p>
          <p>
            <strong className="highlight">
              {cantidadPersonasTotal.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </strong>{" "}
            PERSONAS CAMBIAN SU SMARTPHONE <strong>TODOS LOS MESES</strong>,
          </p>
          <p style={{ marginTop: "1.2rem" }}>
            <strong className="highlight">
              {cantidadGamaMediaMensual.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </strong>{" "}
            CORRESPONDEN A <strong>GAMA MEDIA</strong> y{" "}
            <strong className="highlight">
              {cantidadGamaAltaMensual.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </strong>{" "}
            CORRESPONDEN A <strong>GAMA ALTA</strong>.
          </p>
        </div>
      </div>
      <div style={{ width: "90%", margin: "0 auto", marginTop: "1.5rem", marginBottom: "1rem", textAlign: "center" }}>
        <p>
          Por último, destacar las oportunidades en el segmento B2B, aquí puedes revisar las empresas en los edificios:{" "}
          <a href={urlListadoEmpresas} style={{ color: "#007bff", textDecoration: "none" }}>
            listado empresas por edificio
          </a>
          .
        </p>
      </div>
      <hr style={{ width: "80%", margin: "0 auto", marginTop: "1rem", marginBottom: "1rem" }} />

      <h2 style={{ textAlign: "center", marginBottom: "1rem" }}>INVENTARIO</h2>
      <div className="inventory-container">
        <table className="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th scope="col" className="text-center" onClick={toggleCheckAll} style={{ width: "15%" }}>
                {checkAll ? (
                  <ImCheckboxChecked className="icon" size={18} />
                ) : (
                  <ImCheckboxUnchecked className="icon" size={18} />
                )}
              </th>
              <th scope="col" className="text-center" style={{ width: "35%" }}>
                EDIFICIO
              </th>
              <th scope="col" className="text-center" style={{ width: "25%" }}>
                PERSONAS
              </th>
              <th scope="col" className="text-center" style={{ width: "25%" }}>
                UBICACIÓN
              </th>
            </tr>
          </thead>
          <tbody>
            {edificios.map(({ created_at, edificio, id, ubicacion, personas }) => (
              <tr
                key={created_at}
                onClick={() => toggleCheck(id)}
                className={cx(checkedBuildings[id] && "table-success")}
              >
                <td className="text-center">
                  {checkedBuildings[id] ? (
                    <ImCheckboxChecked className="icon" size={18} />
                  ) : (
                    <ImCheckboxUnchecked className="icon" size={18} />
                  )}
                </td>
                <td>{edificio.toUpperCase()}</td>
                <td>
                  {personas.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>{ubicacion}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const App = () => <SmartPhones forceSubmit={() => {}} edificiosPreseleccionados="" />;

export default App;
