/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth";
import { log, getOportunidadesTD } from "../../utils";
import "./stylesTransformacionDigitalVentas.scss";

export const TransformacionDigitalVentas = () => {
  const [oportunidades, setOportunidades] = useState([]);
  const [loading, setLoading] = useState(true);

  const { pais } = useAuthContext();

  // Fetch opportunities from the sales_td table using getOportunidadesTD
  const fetchOportunidades = async () => {
    try {
      setLoading(true);
      const currentYear = new Date().getFullYear().toString(); // Use the current year (e.g., "2025")
      const data = await getOportunidadesTD({
        pais,
        ano: currentYear, // Pass the current year as the ano parameter
        orderBy: "created_at", // Order by creation date
        ascending: false, // Newest first
      });
      setOportunidades(data);
    } catch (error) {
      log.error("fetchOportunidades", error);
      console.error("Error fetching opportunities:", error);
      setOportunidades([]); // Ensure the table shows "No hay oportunidades" if fetch fails
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOportunidades();
  }, [pais]); // Refetch if pais changes

  // Map status numbers to their corresponding labels
  const statusMap = {
    1: "1. Prospecto",
    2: "2. Lead",
    3: "3. Contacto",
    4: "4. Reunión",
    5: "5. Proyecto",
    6: "6. Seguimiento",
    7: "7. Cerrado: ganado",
    8: "8. Cerrado: perdido",
    9: "9. Pospuesto",
    10: "No interesa",
    11: "No Procede",
  };

  // Format currency based on country
  const moneda = (pais) =>
    ({
      Chile: "$",
      Perú: "S/",
      Uruguay: "$",
    })[pais] || "$";

  const localString = (pais) =>
    ({
      Chile: "es-CL",
      Perú: "es-PE",
      Uruguay: "es-UY",
    })[pais] || "es-UY";

  const decimales = (pais) =>
    ({
      Chile: 0,
      Perú: 2,
      Uruguay: 0,
    })[pais] || 0;

  return (
    <div className="container-oportunidades-tabla">
      <div className="text-center mb-4">
        <h1>TRANSFORMACIÓN DIGITAL</h1>
        <h2 className="h4">Lista de Oportunidades</h2>
      </div>

      {loading ? (
        <div className="text-center">Cargando...</div>
      ) : (
        <div className="row justify-content-center">
          <div className="col-md-12">
            <table
              className="table table-bordered table-striped table-hover table-sm"
              style={{ width: "100%", tableLayout: "fixed" }}
            >
              <thead className="text-center align-middle">
                <tr>
                  <th style={{ width: "5%" }}>ID</th>
                  <th style={{ width: "15%" }}>FECHA CREACIÓN</th>
                  <th style={{ width: "20%" }}>EMPRESA</th>
                  <th style={{ width: "10%" }}>PAÍS</th>
                  <th style={{ width: "15%" }}>ESTADO</th>
                  <th style={{ width: "10%" }}>MONTO</th>
                  <th style={{ width: "15%" }}>PRODUCTO</th>
                  <th style={{ width: "10%" }}>PLAZO (MESES)</th>
                </tr>
              </thead>
              <tbody className="table-striped" style={{ height: "35px", lineHeight: "35px" }}>
                {oportunidades.length === 0 ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No hay oportunidades registradas.
                    </td>
                  </tr>
                ) : (
                  oportunidades.map((oportunidad) => (
                    <tr key={oportunidad.id} style={{ height: "35px", lineHeight: "35px" }}>
                      <td className="text-center align-middle">{oportunidad.id}</td>
                      <td className="text-center align-middle">
                        {new Date(oportunidad.created_at).toLocaleDateString(localString(pais), {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </td>
                      <td className="text-start align-middle">{oportunidad.empresa}</td>
                      <td className="text-center align-middle">{oportunidad.pais}</td>
                      <td className="text-center align-middle">
                        {statusMap[oportunidad.status] || oportunidad.status}
                      </td>
                      <td className="text-end align-middle">
                        {moneda(pais)}{" "}
                        {oportunidad.monto.toLocaleString(localString(pais), {
                          maximumFractionDigits: decimales(pais),
                          minimumFractionDigits: decimales(pais),
                        })}
                      </td>
                      <td className="text-start align-middle">{oportunidad.producto}</td>
                      <td className="text-center align-middle">{oportunidad.plazo}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
