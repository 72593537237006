/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable unicorn/prefer-module */
/* eslint-disable unicorn/consistent-destructuring */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Loader } from "..";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast"; // Added import for toast
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useAuthContext } from "../../context/auth";
import {
  getEdificiosOperativos,
  saveCotizacion,
  saveOportunidad,
  getClientesPublicitarios,
  getIntermediarios,
  getCategorias,
} from "../../utils";
import PdfCotizacion from "../PdfCotizacion";
import { PDFDownloadLink } from "@react-pdf/renderer";
import "./stylesCotizadorProgrammaticCalculadora.scss";

import "./stylesCotizadorProgrammaticCalculadora.scss";

export const CotizadorProgrammaticCalculadora = ({
  inventarioData,
  plazo,
  frecuencia,
  horasSeleccionadas,
  duracion,
  isProgrammaticSelected,
}) => {
  const [, setEdificios] = useState([]);
  const [ascensoresTotal, setAscensoresTotal] = useState(0);
  const [totemsTotal, setTotemsTotal] = useState(0);
  const [coworkTotal, setCoworkTotal] = useState(0);
  const [esperaTotal, setEsperaTotal] = useState(0);
  const [edificiosSeleccionadosAscensores, setEdificiosSeleccionadosAscensores] = useState(0);
  const [edificiosSeleccionadosTotems, setEdificiosSeleccionadosTotems] = useState(0);
  const [edificiosSeleccionadosCowork, setEdificiosSeleccionadosCowork] = useState(0);
  const [edificiosSeleccionadosEspera, setEdificiosSeleccionadosEspera] = useState(0);
  const [edificiosUnicosTotal, setEdificiosUnicosTotal] = useState(0);
  const [ascensoresSeleccionados, setAscensoresSeleccionados] = useState(0);
  const [totemsSeleccionados, setTotemsSeleccionados] = useState(0);
  const [coworkSeleccionados, setCoworkSeleccionados] = useState(0);
  const [esperaSeleccionados, setEsperaSeleccionados] = useState(0);
  const [audienciaSeleccionadaAscensor, setAudienciaSeleccionadaAscensor] = useState(0);
  const [audienciaSeleccionadaTotems, setAudienciaSeleccionadaTotems] = useState(0);
  const [audienciaSeleccionadaCowork, setAudienciaSeleccionadaCowork] = useState(0);
  const [audienciaSeleccionadaEspera, setAudienciaSeleccionadaEspera] = useState(0);
  const [audienciaSeleccionadaTotal, setAudienciaSeleccionadaTotal] = useState(0);
  const [nombresEdificiosSeleccionadosAscensores, setNombresEdificiosSeleccionadosAscensores] = useState([]);
  const [nombresEdificiosSeleccionadosTotems, setNombresEdificiosSeleccionadosTotems] = useState([]);
  const [nombresEdificiosSeleccionadosCowork, setNombresEdificiosSeleccionadosCowork] = useState([]);
  const [nombresEdificiosSeleccionadosEspera, setNombresEdificiosSeleccionadosEspera] = useState([]);
  const [nombresEdificiosNoSeleccionados, setNombresEdificiosNoSeleccionados] = useState([]);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [intermediarios, setIntermediarios] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [clienteParaDescarga, setClienteParaDescarga] = useState(null);
  const [formData, setFormData] = useState(null);
  // const linkRef = useRef(null);

  const { session, nombre, apellido, pais, rol, id } = useAuthContext();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const localString = (pais) =>
    ({
      Chile: "es-CL",
      Perú: "es-PE",
      Uruguay: "es-UY",
    })[pais] || "es-UY";

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      })[pais] || "$",
    []
  );

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  let selectedMoneda;

  const tiposDeCambio = {
    Uruguay: 42,
    Perú: 3.75,
    Chile: 950,
  };

  const operacionTextual = useCallback(
    (pais) =>
      ({
        Chile: "WECAST",
        Perú: "VISIONA",
        Uruguay: "SCREENMEDIA",
      })[pais] || "VISIONA",
    []
  );

  function obtenerTipoDeCambio(pais) {
    return tiposDeCambio[pais];
  }

  const obtenerFechaActual = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    const month = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const day = fecha.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const generarCodigoAleatorio = () => {
    const letras = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let codigo = "";
    for (let i = 0; i < 6; i++) {
      codigo += letras.charAt(Math.floor(Math.random() * letras.length));
    }
    return codigo;
  };

  // Fetch clients, intermediarios, and categorias when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientesData, intermediariosData, categoriasData] = await Promise.all([
          getClientesPublicitarios({ pais }),
          getIntermediarios({ pais }),
          getCategorias(),
        ]);

        let filteredClientes = clientesData;
        if (rol === "comercial") {
          filteredClientes = clientesData.filter((cliente) => cliente.ejecutivo === id.toString());
        } else if (rol === "gerencia" || rol === "superadmin") {
          filteredClientes = clientesData;
        }

        setClientes(filteredClientes);
        setIntermediarios(intermediariosData);
        setCategorias(categoriasData);
      } catch (error) {
        console.error("Error al obtener datos:", error);
        toast.error("No se pudieron cargar los datos.");
      }
    };

    fetchData();
  }, [pais, rol, id]);

  // Function to render client options in the dropdown
  const renderClientesOptions = () => {
    const clientesOrdenados = [...new Set(clientes.map((cliente) => cliente.nombre))].sort();
    return clientesOrdenados.map((cliente, index) => (
      <option key={index} value={cliente}>
        {cliente}
      </option>
    ));
  };

  const getIntermediarioId = (agenciaValue) => {
    if (!agenciaValue) return null; // Manejar caso de valor vacío o nulo

    // Limpiar el valor para evitar problemas con espacios
    const trimmedValue = agenciaValue.trim();

    // Intentar convertir el valor a número para ver si es un ID
    const possibleId = parseInt(trimmedValue, 10);

    if (!isNaN(possibleId)) {
      // Si es un número, buscar por ID
      const intermediario = intermediarios.find((inter) => inter.id === possibleId);
      return intermediario ? intermediario.id : null;
    } else {
      // Si no es un número, buscar por nombre
      const intermediario = intermediarios.find((inter) => inter.nombre.trim() === trimmedValue);
      return intermediario ? intermediario.id : null;
    }
  };

  // Function to get categoria ID based on categoria name
  const getCategoriaId = (categoriaName) => {
    const categoria = categorias.find((cat) => cat.nombre.trim() === categoriaName.trim());
    return categoria ? categoria.id : null;
  };

  const frecuenciaNumero = parseInt(frecuencia, 10);

  useEffect(() => {
    if (inventarioData) {
      setAscensoresSeleccionados(inventarioData.ascensoresSeleccionados || 0);
      setTotemsSeleccionados(inventarioData.totemsSeleccionados || 0);
      setCoworkSeleccionados(inventarioData.coworkSeleccionados || 0);
      setEsperaSeleccionados(inventarioData.esperaSeleccionados || 0);
      setAudienciaSeleccionadaAscensor(inventarioData.audienciaSeleccionadaAscensor || 0);
      setAudienciaSeleccionadaTotems(inventarioData.audienciaSeleccionadaTotems || 0);
      setAudienciaSeleccionadaCowork(inventarioData.audienciaSeleccionadaCowork || 0);
      setAudienciaSeleccionadaEspera(inventarioData.audienciaSeleccionadaEspera || 0); // Usar audiencia de espera
      setAudienciaSeleccionadaTotal(inventarioData.audienciaSeleccionadaTotal || 0);
      setEdificiosSeleccionadosAscensores(inventarioData.edificiosSeleccionadosAscensores?.length || 0);
      setEdificiosSeleccionadosTotems(inventarioData.edificiosSeleccionadosTotems?.length || 0);
      setEdificiosSeleccionadosCowork(inventarioData.edificiosSeleccionadosCowork?.length || 0);
      setEdificiosSeleccionadosEspera(inventarioData.edificiosSeleccionadosEspera?.length || 0); // Usar edificios de espera

      const totalEdificiosUnicos = new Set([
        ...(Array.isArray(inventarioData.edificiosSeleccionadosAscensores)
          ? inventarioData.edificiosSeleccionadosAscensores
          : []),
        ...(Array.isArray(inventarioData.edificiosSeleccionadosTotems)
          ? inventarioData.edificiosSeleccionadosTotems
          : []),
        ...(Array.isArray(inventarioData.edificiosSeleccionadosCowork)
          ? inventarioData.edificiosSeleccionadosCowork
          : []),
        ...(Array.isArray(inventarioData.edificiosSeleccionadosEspera)
          ? inventarioData.edificiosSeleccionadosEspera
          : []),
      ]).size;

      setEdificiosUnicosTotal(totalEdificiosUnicos);
    }
  }, [inventarioData]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const newEdificios = await getEdificiosOperativos({ pais });
        newEdificios.sort((a, b) => a.edificio.localeCompare(b.edificio));
        setEdificios(newEdificios);

        const totalAscensores = newEdificios.reduce((sum, edificio) => sum + (edificio.pantallas || 0), 0);
        const totalTotems = newEdificios.reduce((sum, edificio) => sum + (edificio.totems || 0), 0);
        const totalCowork = newEdificios.reduce((sum, edificio) => sum + (edificio.cowork || 0), 0);
        const totalEspera = newEdificios.reduce((sum, edificio) => sum + (edificio.espera || 0), 0);

        setAscensoresTotal(totalAscensores);
        setTotemsTotal(totalTotems);
        setCoworkTotal(totalCowork);
        setEsperaTotal(totalEspera);

        const nombresEdificiosSeleccionadosAscensores = newEdificios
          .filter(
            (edificio) =>
              Array.isArray(inventarioData.edificiosSeleccionadosAscensores) &&
              inventarioData.edificiosSeleccionadosAscensores.includes(edificio.id)
          )
          .map((edificio) => edificio.edificio);

        const nombresEdificiosSeleccionadosTotems = newEdificios
          .filter(
            (edificio) =>
              Array.isArray(inventarioData.edificiosSeleccionadosTotems) &&
              inventarioData.edificiosSeleccionadosTotems.includes(edificio.id)
          )
          .map((edificio) => edificio.edificio);

        const nombresEdificiosSeleccionadosCowork = newEdificios
          .filter(
            (edificio) =>
              Array.isArray(inventarioData.edificiosSeleccionadosCowork) &&
              inventarioData.edificiosSeleccionadosCowork.includes(edificio.id)
          )
          .map((edificio) => edificio.edificio);

        const nombresEdificiosSeleccionadosEspera = newEdificios
          .filter(
            (edificio) =>
              Array.isArray(inventarioData.edificiosSeleccionadosEspera) &&
              inventarioData.edificiosSeleccionadosEspera.includes(edificio.id)
          )
          .map((edificio) => edificio.edificio);

        const nombresEdificiosNoSeleccionados = newEdificios
          .filter(
            (edificio) =>
              Array.isArray(inventarioData.edificiosSeleccionadosAscensores) &&
              Array.isArray(inventarioData.edificiosSeleccionadosTotems) &&
              Array.isArray(inventarioData.edificiosSeleccionadosCowork) &&
              Array.isArray(inventarioData.edificiosSeleccionadosEspera) &&
              !inventarioData.edificiosSeleccionadosAscensores.includes(edificio.id) &&
              !inventarioData.edificiosSeleccionadosTotems.includes(edificio.id) &&
              !inventarioData.edificiosSeleccionadosCowork.includes(edificio.id) &&
              !inventarioData.edificiosSeleccionadosEspera.includes(edificio.id)
          )
          .map((edificio) => edificio.edificio);

        setNombresEdificiosSeleccionadosAscensores(nombresEdificiosSeleccionadosAscensores);
        setNombresEdificiosSeleccionadosTotems(nombresEdificiosSeleccionadosTotems);
        setNombresEdificiosSeleccionadosCowork(nombresEdificiosSeleccionadosCowork);
        setNombresEdificiosSeleccionadosEspera(nombresEdificiosSeleccionadosEspera);
        setNombresEdificiosNoSeleccionados(nombresEdificiosNoSeleccionados);
      } catch (error) {
        console.error("Error al obtener edificios:", error);
      }
    };

    initialize();
  }, [pais, inventarioData]);

  const { intervalos, horas, options } = horasSeleccionadas;

  let horasFinal = 0;

  if (intervalos && intervalos.length > 0) {
    horasFinal = horas;
  } else if (options && options.length > 0) {
    horasFinal = options[0].hours;
  } else {
    horasFinal = 0;
  }

  // Cálculos para cada tipo de pantalla
  const salidasAscensores = ascensoresSeleccionados * plazo * frecuenciaNumero * horasFinal;
  const salidasTotems = totemsSeleccionados * plazo * frecuenciaNumero * horasFinal;
  const salidasCowork = coworkSeleccionados * plazo * frecuenciaNumero * horasFinal;
  const salidasEspera = esperaSeleccionados * plazo * frecuenciaNumero * horasFinal;

  const valle = 0.15;
  const picoMañana = 0.25;
  const picoMediodia = 0.35;
  const picoTarde = 0.25;

  const contactosTotalAscensores = audienciaSeleccionadaAscensor * 7.48;
  const contactosTotalTotems = audienciaSeleccionadaTotems * 7.48;
  const contactosTotalCowork = audienciaSeleccionadaCowork * 7.48;
  const contactosTotalEspera = audienciaSeleccionadaEspera * 7.48; // Usar audiencia específica de espera

  const contactosValleAscensores = contactosTotalAscensores * valle;
  const contactosMañanaAscensores = contactosTotalAscensores * picoMañana;
  const contactosMediodiaAscensores = contactosTotalAscensores * picoMediodia;
  const contactosTardeAscensores = contactosTotalAscensores * picoTarde;

  const contactosValleTotems = contactosTotalTotems * valle;
  const contactosMañanaTotems = contactosTotalTotems * picoMañana;
  const contactosMediodiaTotems = contactosTotalTotems * picoMediodia;
  const contactosTardeTotems = contactosTotalTotems * picoTarde;

  const contactosValleCowork = contactosTotalCowork * valle;
  const contactosMañanaCowork = contactosTotalCowork * picoMañana;
  const contactosMediodiaCowork = contactosTotalCowork * picoMediodia;
  const contactosTardeCowork = contactosTotalCowork * picoTarde;

  const contactosValleEspera = contactosTotalEspera * valle;
  const contactosMañanaEspera = contactosTotalEspera * picoMañana;
  const contactosMediodiaEspera = contactosTotalEspera * picoMediodia;
  const contactosTardeEspera = contactosTotalEspera * picoTarde;

  const contactosVallexHoraAscensores = contactosValleAscensores / 8;
  const contactosMañanaxHoraAscensores = contactosMañanaAscensores / 2;
  const contactosMediodiaxHoraAscensores = contactosMediodiaAscensores / 2;
  const contactosTardexHoraAscensores = contactosTardeAscensores / 2;

  const contactosVallexHoraTotems = contactosValleTotems / 8;
  const contactosMañanaxHoraTotems = contactosMañanaTotems / 2;
  const contactosMediodiaxHoraTotems = contactosMediodiaTotems / 2;
  const contactosTardexHoraTotems = contactosTardeTotems / 2;

  const contactosVallexHoraCowork = contactosValleCowork / 8;
  const contactosMañanaxHoraCowork = contactosMañanaCowork / 2;
  const contactosMediodiaxHoraCowork = contactosMediodiaCowork / 2;
  const contactosTardexHoraCowork = contactosTardeCowork / 2;

  const contactosVallexHoraEspera = contactosValleEspera / 8;
  const contactosMañanaxHoraEspera = contactosMañanaEspera / 2;
  const contactosMediodiaxHoraEspera = contactosMediodiaEspera / 2;
  const contactosTardexHoraEspera = contactosTardeEspera / 2;

  const contactosVallexIntervaloAscensores = contactosVallexHoraAscensores / 2;
  const contactosMañanaxIntervaloAscensores = contactosMañanaxHoraAscensores / 2;
  const contactosMediodiaxIntervaloAscensores = contactosMediodiaxHoraAscensores / 2;
  const contactosTardexIntervaloAscensores = contactosTardexHoraAscensores / 2;

  const contactosVallexIntervaloTotems = contactosVallexHoraTotems / 2;
  const contactosMañanaxIntervaloTotems = contactosMañanaxHoraTotems / 2;
  const contactosMediodiaxIntervaloTotems = contactosMediodiaxHoraTotems / 2;
  const contactosTardexIntervaloTotems = contactosTardexHoraTotems / 2;

  const contactosVallexIntervaloCowork = contactosVallexHoraCowork / 2;
  const contactosMañanaxIntervaloCowork = contactosMañanaxHoraCowork / 2;
  const contactosMediodiaxIntervaloCowork = contactosMediodiaxHoraCowork / 2;
  const contactosTardexIntervaloCowork = contactosTardexHoraCowork / 2;

  const contactosVallexIntervaloEspera = contactosVallexHoraEspera / 2;
  const contactosMañanaxIntervaloEspera = contactosMañanaxHoraEspera / 2;
  const contactosMediodiaxIntervaloEspera = contactosMediodiaxHoraEspera / 2;
  const contactosTardexIntervaloEspera = contactosTardexHoraEspera / 2;

  const contactosPorIntervaloPorPantallaValleAscensores = contactosVallexIntervaloAscensores / ascensoresSeleccionados;
  const contactosPorIntervaloPorPantallaMañanaAscensores =
    contactosMañanaxIntervaloAscensores / ascensoresSeleccionados;
  const contactosPorIntervaloPorPantallaMediodiaAscensores =
    contactosMediodiaxIntervaloAscensores / ascensoresSeleccionados;
  const contactosPorIntervaloPorPantallaTardeAscensores = contactosTardexIntervaloAscensores / ascensoresSeleccionados;

  const contactosPorIntervaloPorPantallaValleTotems = contactosVallexIntervaloTotems / totemsSeleccionados;
  const contactosPorIntervaloPorPantallaMañanaTotems = contactosMañanaxIntervaloTotems / totemsSeleccionados;
  const contactosPorIntervaloPorPantallaMediodiaTotems = contactosMediodiaxIntervaloTotems / totemsSeleccionados;
  const contactosPorIntervaloPorPantallaTardeTotems = contactosTardexIntervaloTotems / totemsSeleccionados;

  const contactosPorIntervaloPorPantallaValleCowork = contactosVallexIntervaloCowork / coworkSeleccionados;
  const contactosPorIntervaloPorPantallaMañanaCowork = contactosMañanaxIntervaloCowork / coworkSeleccionados;
  const contactosPorIntervaloPorPantallaMediodiaCowork = contactosMediodiaxIntervaloCowork / coworkSeleccionados;
  const contactosPorIntervaloPorPantallaTardeCowork = contactosTardexIntervaloCowork / coworkSeleccionados;

  const contactosPorIntervaloPorPantallaValleEspera = contactosVallexIntervaloEspera / esperaSeleccionados;
  const contactosPorIntervaloPorPantallaMañanaEspera = contactosMañanaxIntervaloEspera / esperaSeleccionados;
  const contactosPorIntervaloPorPantallaMediodiaEspera = contactosMediodiaxIntervaloEspera / esperaSeleccionados;
  const contactosPorIntervaloPorPantallaTardeEspera = contactosTardexIntervaloEspera / esperaSeleccionados;

  let countValle = 0;
  let countMañana = 0;
  let countMediodia = 0;
  let countTarde = 0;

  intervalos.forEach((intervalo) => {
    if (
      intervalo === "08:00 - 08:30" ||
      intervalo === "08:30 - 09:00" ||
      intervalo === "09:00 - 09:30" ||
      intervalo === "09:30 - 10:00"
    ) {
      countMañana++;
    } else if (
      intervalo === "12:30 - 13:00" ||
      intervalo === "13:00 - 13:30" ||
      intervalo === "13:30 - 14:00" ||
      intervalo === "14:00 - 14:30"
    ) {
      countMediodia++;
    } else if (
      intervalo === "17:00 - 17:30" ||
      intervalo === "17:30 - 18:00" ||
      intervalo === "18:00 - 18:30" ||
      intervalo === "18:30 - 19:00"
    ) {
      countTarde++;
    } else {
      countValle++;
    }
  });

  const totalContactosValleAscensores =
    contactosPorIntervaloPorPantallaValleAscensores * countValle * plazo * ascensoresSeleccionados;
  const totalContactosMañanaAscensores =
    contactosPorIntervaloPorPantallaMañanaAscensores * countMañana * plazo * ascensoresSeleccionados;
  const totalContactosMediodiaAscensores =
    contactosPorIntervaloPorPantallaMediodiaAscensores * countMediodia * plazo * ascensoresSeleccionados;
  const totalContactosTardeAscensores =
    contactosPorIntervaloPorPantallaTardeAscensores * countTarde * plazo * ascensoresSeleccionados;

  const totalContactosValleTotems =
    contactosPorIntervaloPorPantallaValleTotems * countValle * plazo * totemsSeleccionados;
  const totalContactosMañanaTotems =
    contactosPorIntervaloPorPantallaMañanaTotems * countMañana * plazo * totemsSeleccionados;
  const totalContactosMediodiaTotems =
    contactosPorIntervaloPorPantallaMediodiaTotems * countMediodia * plazo * totemsSeleccionados;
  const totalContactosTardeTotems =
    contactosPorIntervaloPorPantallaTardeTotems * countTarde * plazo * totemsSeleccionados;

  const totalContactosValleCowork =
    contactosPorIntervaloPorPantallaValleCowork * countValle * plazo * coworkSeleccionados;
  const totalContactosMañanaCowork =
    contactosPorIntervaloPorPantallaMañanaCowork * countMañana * plazo * coworkSeleccionados;
  const totalContactosMediodiaCowork =
    contactosPorIntervaloPorPantallaMediodiaCowork * countMediodia * plazo * coworkSeleccionados;
  const totalContactosTardeCowork =
    contactosPorIntervaloPorPantallaTardeCowork * countTarde * plazo * coworkSeleccionados;

  const totalContactosValleEspera =
    contactosPorIntervaloPorPantallaValleEspera * countValle * plazo * esperaSeleccionados;
  const totalContactosMañanaEspera =
    contactosPorIntervaloPorPantallaMañanaEspera * countMañana * plazo * esperaSeleccionados;
  const totalContactosMediodiaEspera =
    contactosPorIntervaloPorPantallaMediodiaEspera * countMediodia * plazo * esperaSeleccionados;
  const totalContactosTardeEspera =
    contactosPorIntervaloPorPantallaTardeEspera * countTarde * plazo * esperaSeleccionados;

  let totalContactosCampañaAscensores;
  if (intervalos && intervalos.length > 0) {
    totalContactosCampañaAscensores =
      totalContactosValleAscensores +
      totalContactosMañanaAscensores +
      totalContactosMediodiaAscensores +
      totalContactosTardeAscensores;
  } else if (options && options.length > 0) {
    if (options[0].label.includes("FULL")) {
      totalContactosCampañaAscensores = audienciaSeleccionadaAscensor * 7.48 * plazo;
    } else if (options[0].label.includes("AM") || options[0].label.includes("PM")) {
      totalContactosCampañaAscensores = ((audienciaSeleccionadaAscensor * 7.48) / 2) * plazo;
    } else {
      totalContactosCampañaAscensores = 0;
    }
  } else {
    totalContactosCampañaAscensores = 0;
  }

  let totalContactosCampañaTotems;
  if (intervalos && intervalos.length > 0) {
    totalContactosCampañaTotems =
      totalContactosValleTotems + totalContactosMañanaTotems + totalContactosMediodiaTotems + totalContactosTardeTotems;
  } else if (options && options.length > 0) {
    if (options[0].label.includes("FULL")) {
      totalContactosCampañaTotems = audienciaSeleccionadaTotems * 7.48 * plazo;
    } else if (options[0].label.includes("AM") || options[0].label.includes("PM")) {
      totalContactosCampañaTotems = ((audienciaSeleccionadaTotems * 7.48) / 2) * plazo;
    } else {
      totalContactosCampañaTotems = 0;
    }
  } else {
    totalContactosCampañaTotems = 0;
  }

  let totalContactosCampañaCowork;
  if (intervalos && intervalos.length > 0) {
    totalContactosCampañaCowork =
      totalContactosValleCowork + totalContactosMañanaCowork + totalContactosMediodiaCowork + totalContactosTardeCowork;
  } else if (options && options.length > 0) {
    if (options[0].label.includes("FULL")) {
      totalContactosCampañaCowork = audienciaSeleccionadaCowork * 7.48 * plazo;
    } else if (options[0].label.includes("AM") || options[0].label.includes("PM")) {
      totalContactosCampañaCowork = ((audienciaSeleccionadaCowork * 7.48) / 2) * plazo;
    } else {
      totalContactosCampañaCowork = 0;
    }
  } else {
    totalContactosCampañaCowork = 0;
  }

  const totalContactosCampañaEspera =
    intervalos && intervalos.length > 0
      ? totalContactosValleEspera +
        totalContactosMañanaEspera +
        totalContactosMediodiaEspera +
        totalContactosTardeEspera
      : options && options.length > 0 && options[0].label.includes("FULL")
        ? audienciaSeleccionadaEspera * 7.48 * plazo
        : options && options.length > 0 && (options[0].label.includes("AM") || options[0].label.includes("PM"))
          ? ((audienciaSeleccionadaEspera * 7.48) / 2) * plazo
          : 0;

  const coeficientesFrecuencia = {
    5: { valle: 0.0556, picoMañana: 0.1042, picoMediodia: 0.125, picoTarde: 0.1042 },
    10: { valle: 0.1111, picoMañana: 0.2083, picoMediodia: 0.25, picoTarde: 0.2083 },
    15: { valle: 0.1667, picoMañana: 0.3125, picoMediodia: 0.375, picoTarde: 0.3125 },
    20: { valle: 0.2222, picoMañana: 0.4167, picoMediodia: 0.5, picoTarde: 0.4167 },
    25: { valle: 0.2778, picoMañana: 0.5208, picoMediodia: 0.625, picoTarde: 0.5208 },
    30: { valle: 0.3333, picoMañana: 0.625, picoMediodia: 0.75, picoTarde: 0.625 },
  };

  let impactosTotalAscensores = 0;

  if (intervalos && intervalos.length > 0) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};
    if (countValle > 0) impactosTotalAscensores += totalContactosValleAscensores * (coeficientes.valle || 0);
    if (countMañana > 0 || countTarde > 0)
      impactosTotalAscensores +=
        (totalContactosMañanaAscensores + totalContactosTardeAscensores) * (coeficientes.picoMañana || 0);
    if (countMediodia > 0)
      impactosTotalAscensores += totalContactosMediodiaAscensores * (coeficientes.picoMediodia || 0);
  } else if (options && options.length > 0 && options[0].label.includes("FULL")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};
    impactosTotalAscensores =
      totalContactosCampañaAscensores * valle * (coeficientes.valle || 0) +
      totalContactosCampañaAscensores * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaAscensores * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaAscensores * picoTarde * (coeficientes.picoTarde || 0);
  } else if (options && options.length > 0 && (options[0].label.includes("AM") || options[0].label.includes("PM"))) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};
    impactosTotalAscensores =
      totalContactosCampañaAscensores * valle * (coeficientes.valle || 0) +
      totalContactosCampañaAscensores * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaAscensores * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaAscensores * picoTarde * (coeficientes.picoTarde || 0);
  } else {
    impactosTotalAscensores = 0;
  }
  impactosTotalAscensores = Math.round(impactosTotalAscensores * 100) / 100;

  let impactosTotalTotems = 0;

  if (intervalos && intervalos.length > 0) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};
    if (countValle > 0) impactosTotalTotems += totalContactosValleTotems * (coeficientes.valle || 0);
    if (countMañana > 0 || countTarde > 0)
      impactosTotalTotems += (totalContactosMañanaTotems + totalContactosTardeTotems) * (coeficientes.picoMañana || 0);
    if (countMediodia > 0) impactosTotalTotems += totalContactosMediodiaTotems * (coeficientes.picoMediodia || 0);
  } else if (options && options.length > 0 && options[0].label.includes("FULL")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};
    impactosTotalTotems =
      totalContactosCampañaTotems * valle * (coeficientes.valle || 0) +
      totalContactosCampañaTotems * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaTotems * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaTotems * picoTarde * (coeficientes.picoTarde || 0);
  } else if (options && options.length > 0 && (options[0].label.includes("AM") || options[0].label.includes("PM"))) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};
    impactosTotalTotems =
      totalContactosCampañaTotems * valle * (coeficientes.valle || 0) +
      totalContactosCampañaTotems * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaTotems * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaTotems * picoTarde * (coeficientes.picoTarde || 0);
  } else {
    impactosTotalTotems = 0;
  }
  impactosTotalTotems = Math.round(impactosTotalTotems * 100) / 100;

  let impactosTotalCowork = 0;

  if (intervalos && intervalos.length > 0) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};
    if (countValle > 0) impactosTotalCowork += totalContactosValleCowork * (coeficientes.valle || 0);
    if (countMañana > 0 || countTarde > 0)
      impactosTotalCowork += (totalContactosMañanaCowork + totalContactosTardeCowork) * (coeficientes.picoMañana || 0);
    if (countMediodia > 0) impactosTotalCowork += totalContactosMediodiaCowork * (coeficientes.picoMediodia || 0);
  } else if (options && options.length > 0 && options[0].label.includes("FULL")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};
    impactosTotalCowork =
      totalContactosCampañaCowork * valle * (coeficientes.valle || 0) +
      totalContactosCampañaCowork * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaCowork * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaCowork * picoTarde * (coeficientes.picoTarde || 0);
  } else if (options && options.length > 0 && (options[0].label.includes("AM") || options[0].label.includes("PM"))) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};
    impactosTotalCowork =
      totalContactosCampañaCowork * valle * (coeficientes.valle || 0) +
      totalContactosCampañaCowork * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaCowork * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaCowork * picoTarde * (coeficientes.picoTarde || 0);
  } else {
    impactosTotalCowork = 0;
  }
  impactosTotalCowork = Math.round(impactosTotalCowork * 100) / 100;

  let impactosTotalEspera = 0;

  if (intervalos && intervalos.length > 0) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};
    if (countValle > 0) impactosTotalEspera += totalContactosValleEspera * (coeficientes.valle || 0);
    if (countMañana > 0 || countTarde > 0)
      impactosTotalEspera += (totalContactosMañanaEspera + totalContactosTardeEspera) * (coeficientes.picoMañana || 0);
    if (countMediodia > 0) impactosTotalEspera += totalContactosMediodiaEspera * (coeficientes.picoMediodia || 0);
  } else if (options && options.length > 0 && options[0].label.includes("FULL")) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};
    impactosTotalEspera =
      totalContactosCampañaEspera * valle * (coeficientes.valle || 0) +
      totalContactosCampañaEspera * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaEspera * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaEspera * picoTarde * (coeficientes.picoTarde || 0);
  } else if (options && options.length > 0 && (options[0].label.includes("AM") || options[0].label.includes("PM"))) {
    const coeficientes = coeficientesFrecuencia[frecuenciaNumero] || {};
    impactosTotalEspera =
      totalContactosCampañaEspera * valle * (coeficientes.valle || 0) +
      totalContactosCampañaEspera * picoMañana * (coeficientes.picoMañana || 0) +
      totalContactosCampañaEspera * picoMediodia * (coeficientes.picoMediodia || 0) +
      totalContactosCampañaEspera * picoTarde * (coeficientes.picoTarde || 0);
  } else {
    impactosTotalEspera = 0;
  }
  impactosTotalEspera = Math.round(impactosTotalEspera * 100) / 100;

  let duracionIncidenciaCotizacion;
  if (duracion === 6) duracionIncidenciaCotizacion = 1;
  else if (duracion === 7) duracionIncidenciaCotizacion = 1.0125;
  else if (duracion === 8) duracionIncidenciaCotizacion = 1.025;
  else if (duracion === 9) duracionIncidenciaCotizacion = 1.0375;
  else if (duracion === 10) duracionIncidenciaCotizacion = 1.05;
  else if (duracion === 11) duracionIncidenciaCotizacion = 1.0625;
  else if (duracion === 12) duracionIncidenciaCotizacion = 1.075;
  else if (duracion === 13) duracionIncidenciaCotizacion = 1.0875;
  else if (duracion === 14) duracionIncidenciaCotizacion = 1.1;
  else if (duracion === 15) duracionIncidenciaCotizacion = 1.1125;
  else duracionIncidenciaCotizacion = 0;

  const cpmReferencia = 6.9 * duracionIncidenciaCotizacion;

  const cpmAscensores =
    (intervalos.length > 0
      ? cpmReferencia * 2.5
      : options && options.length > 0 && options[0].label.includes("FULL")
        ? cpmReferencia * obtenerTipoDeCambio(pais)
        : cpmReferencia * 1.5 * obtenerTipoDeCambio(pais)) / 1000;

  const cpmTotems = cpmAscensores * 0.9;
  const cpmCowork = cpmAscensores * 0.9;
  const cpmEspera = cpmAscensores * 0.9;

  const inversionAscensores = impactosTotalAscensores * cpmAscensores;
  const inversionTotems = impactosTotalTotems * cpmTotems;
  const inversionCowork = impactosTotalCowork * cpmCowork;
  const inversionEspera = impactosTotalEspera * cpmEspera;

  const salidasTotales = salidasAscensores + salidasTotems + salidasCowork + salidasEspera;

  const impactosTotales =
    (impactosTotalAscensores || 0) +
    (impactosTotalTotems || 0) +
    (impactosTotalCowork || 0) +
    (impactosTotalEspera || 0);

  if (intervalos && intervalos.length > 0) {
    selectedMoneda = "US$";
  } else if (options && options.length > 0 && options[0].label.includes("FULL")) {
    selectedMoneda = moneda(pais);
  } else {
    selectedMoneda = moneda(pais);
  }

  let descuentoDuracion;
  if (duracion === 6) descuentoDuracion = 0.01;
  else if (duracion === 7) descuentoDuracion = 0.0115;
  else if (duracion === 8) descuentoDuracion = 0.0125;
  else if (duracion === 9) descuentoDuracion = 0.0135;
  else if (duracion === 10) descuentoDuracion = 0.0145;
  else if (duracion === 11) descuentoDuracion = 0.0155;
  else if (duracion === 12) descuentoDuracion = 0.0165;
  else if (duracion === 13) descuentoDuracion = 0.0175;
  else if (duracion === 14) descuentoDuracion = 0.0185;
  else if (duracion === 15) descuentoDuracion = 0.0195;
  else descuentoDuracion = 0;

  const totalPantallasDisponibles = ascensoresTotal + totemsTotal + coworkTotal + esperaTotal;
  const totalPantallasSeleccionadas =
    ascensoresSeleccionados + totemsSeleccionados + coworkSeleccionados + esperaSeleccionados;

  const descuentoMaximoInventario = 0.075;
  let descuentoInventario = 0;
  if (totalPantallasSeleccionadas > 0 && totalPantallasDisponibles > 0) {
    descuentoInventario = (totalPantallasSeleccionadas / totalPantallasDisponibles) * descuentoMaximoInventario;
    descuentoInventario = Math.min(descuentoInventario, descuentoMaximoInventario);
  }

  const descuentoTotalPorInventario = descuentoInventario;
  const descuentoPlazo = (plazo / 260) * 0.05;

  let descuentoFrecuenciaNuevo;

  if (frecuenciaNumero === 5) {
    descuentoFrecuenciaNuevo = 0.02;
  } else if (frecuenciaNumero >= 10 && frecuenciaNumero <= 30 && frecuenciaNumero % 5 === 0) {
    const incremento = (frecuenciaNumero - 5) / 5; // Calcula cuántos pasos de 5 hay
    descuentoFrecuenciaNuevo = (0.02 + incremento * 0.02) * 0.9;
  } else {
    descuentoFrecuenciaNuevo = 0;
  }

  const descuentoFrecuenciaNuevoHoras = horasFinal === 7 ? descuentoFrecuenciaNuevo / 2.2 : descuentoFrecuenciaNuevo;

  const descuentoGlobal =
    descuentoTotalPorInventario + descuentoPlazo + descuentoFrecuenciaNuevoHoras + descuentoDuracion;

  const inversionAscensoresFinal =
    intervalos.length > 0 ? inversionAscensores : inversionAscensores * (1 - descuentoGlobal);
  const inversionTotemsFinal = intervalos.length > 0 ? inversionTotems : inversionTotems * (1 - descuentoGlobal);
  const inversionCoworkFinal = intervalos.length > 0 ? inversionCowork : inversionCowork * (1 - descuentoGlobal);
  const inversionEsperaFinal = intervalos.length > 0 ? inversionEspera : inversionEspera * (1 - descuentoGlobal);

  const tarifaFriaAscensores = (100 * inversionAscensores) / (100 - descuentoGlobal);
  const tarifaFriaTotems = (100 * inversionTotems) / (100 - descuentoGlobal);
  const tarifaFriaCowork = (100 * inversionCowork) / (100 - descuentoGlobal);
  const tarifaFriaEspera = (100 * inversionEspera) / (100 - descuentoGlobal);

  const tarifaFriaTotal =
    (tarifaFriaAscensores || 0) + (tarifaFriaTotems || 0) + (tarifaFriaCowork || 0) + (tarifaFriaEspera || 0);

  const inversionFinalTotal =
    (inversionAscensoresFinal || 0) +
    (inversionTotemsFinal || 0) +
    (inversionCoworkFinal || 0) +
    (inversionEsperaFinal || 0);

  const cpmAscensoresFinal = (inversionAscensoresFinal / impactosTotalAscensores) * 1000;
  const cpmTotemsFinal = (inversionTotemsFinal / impactosTotalTotems) * 1000;
  const cpmCoworkFinal = (inversionCoworkFinal / impactosTotalCowork) * 1000;
  const cpmEsperaFinal = (inversionEsperaFinal / impactosTotalEspera) * 1000;

  const cpmTotalFinal =
    (((inversionAscensoresFinal || 0) +
      (inversionTotemsFinal || 0) +
      (inversionCoworkFinal || 0) +
      (inversionEsperaFinal || 0)) /
      ((impactosTotalAscensores || 0) +
        (impactosTotalTotems || 0) +
        (impactosTotalCowork || 0) +
        (impactosTotalEspera || 0))) *
    1000;

  const todosLosTotalesCompletos = () =>
    edificiosUnicosTotal > 0 &&
    ascensoresSeleccionados + totemsSeleccionados + coworkSeleccionados + esperaSeleccionados > 0 &&
    audienciaSeleccionadaTotal > 0 &&
    horasFinal > 0 &&
    plazo > 0 &&
    salidasTotales > 0 &&
    impactosTotales > 0 &&
    duracion > 0 &&
    tarifaFriaTotal > 0 &&
    inversionFinalTotal > 0 &&
    cpmTotalFinal > 0;

  const usuarioNombre = nombre || "usuario";
  const usuarioApellido = apellido || "usuario";

  const codigoGenerado = generarCodigoAleatorio();

  const handleSaveCotizacion = async () => {
    const cotizacionData = {
      ascensoresSeleccionados,
      audienciaSeleccionadaAscensor,
      audienciaSeleccionadaCowork,
      audienciaSeleccionadaTotal,
      audienciaSeleccionadaTotems,
      codigoGenerado,
      coworkSeleccionados,
      cpmAscensoresFinal,
      cpmCoworkFinal,
      cpmEsperaFinal,
      cpmTotalFinal,
      cpmTotemsFinal,
      duracion,
      edificiosSeleccionadosAscensores,
      edificiosSeleccionadosCowork,
      edificiosSeleccionadosEspera,
      edificiosSeleccionadosTotems,
      edificiosUnicosTotal,
      esperaSeleccionados,
      horas,
      horasFinal,
      impactosTotalAscensores,
      impactosTotalCowork,
      impactosTotalEspera,
      impactosTotalTotems,
      impactosTotales,
      intervalos,
      inversionAscensoresFinal,
      inversionCoworkFinal,
      inversionEsperaFinal,
      inversionFinalTotal,
      inversionTotemsFinal,
      isProgrammaticSelected,
      nombresEdificiosNoSeleccionados,
      nombresEdificiosSeleccionadosAscensores,
      nombresEdificiosSeleccionadosCowork,
      nombresEdificiosSeleccionadosEspera,
      nombresEdificiosSeleccionadosTotems,
      options,
      pais,
      plazo,
      salidasAscensores,
      salidasCowork,
      salidasEspera,
      salidasTotales,
      salidasTotems,
      tarifaFriaAscensores,
      tarifaFriaCowork,
      tarifaFriaEspera,
      tarifaFriaTotal,
      tarifaFriaTotems,
      totemsSeleccionados,
      usuarioApellido,
      usuarioNombre,
    };

    await saveCotizacion(cotizacionData);
  };

  const handleLinkOportunidad = async (data) => {
    const selectedClient = clientes.find((cliente) => cliente.nombre === data.clientePublicitario);

    const intermediarioId = selectedClient ? getIntermediarioId(selectedClient.agencia) : null;

    const categoriaId = selectedClient ? getCategoriaId(selectedClient.categoria) : null;

    if (!selectedClient) {
      console.warn("No client found for:", data.clientePublicitario);
    }

    const edificiosparticipantesIds = [
      ...(inventarioData.edificiosSeleccionadosAscensores || []),
      ...(inventarioData.edificiosSeleccionadosTotems || []),
      ...(inventarioData.edificiosSeleccionadosCowork || []),
      ...(inventarioData.edificiosSeleccionadosEspera || []),
    ]
      .filter(Boolean)
      .join(",");

    const oportunidadData = {
      cliente: data.clientePublicitario || "Sin cliente",
      campana: data.campana,
      canal: data.canal,
      mes: data.mes,
      mes_cierre: data.mesCierre,
      ano: parseInt(new Date().getFullYear().toString(), 10),
      importe: inversionFinalTotal || 0,
      estatus: data.estatus,
      ejecutivo: id,
      intermediario: intermediarioId, // Este valor debería ser un ID válido, no null
      tipo: isProgrammaticSelected ? "p" : "d",
      edificiosparticipantes: edificiosparticipantesIds,
      edificiostotales: edificiosUnicosTotal || 0,
      pantallastotales:
        (ascensoresSeleccionados || 0) +
        (totemsSeleccionados || 0) +
        (coworkSeleccionados || 0) +
        (esperaSeleccionados || 0),
      formatopauta: frecuencia || "0",
      periodopauta: plazo || 0,
      duracion: duracion || 0,
      categoria: categoriaId,
      pais,
      facturafecha: null,
      plazocobro: null,
      facturafechacobro: null,
      facturanumero: null,
      eliminada: false,
      code: codigoGenerado,
    };

    try {
      await saveOportunidad(oportunidadData);
      setFormData(data);
      setClienteParaDescarga(data.clientePublicitario);
    } catch (error) {
      console.error("Error al vincular oportunidad:", error.message, error.stack);
      toast.error(`Error al vincular oportunidad: ${error.message}`);
      throw error;
    }
  };

  const canLinkOpportunity = ["comercial", "gerencia", "superadmin"].includes(rol);

  if (session === undefined) return <Loader fullPage />;

  return (
    <div className="table-container-a">
      <div className="logo-container mb-4">
        <img src={logo} alt={`${pais} Logo`} className="logo" />
      </div>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>CIRCUITO</th>
            <th>EDIFICIOS</th>
            <th>PANTALLAS</th>
            <th>AUDIENCIA</th>
            <th>HORAS ON</th>
            <th>PLAZO (días)</th>
            <th>IMPRESIONES</th>
            <th>IMPACTOS</th>
            <th>
              DURACIÓN <br />
              (segundos)
            </th>
            <th>COTIZACIÓN</th>
            {!isProgrammaticSelected && <th className="bg-danger">DESCUENTO</th>}
            <th className="bg-success">INVERSIÓN</th>
            <th className="bg-primary">CPM</th>
          </tr>
        </thead>
        <tbody>
          {/* Ascensores */}
          <tr>
            <th scope="row" className="text-start">
              ASCENSORES
            </th>
            <td>{ascensoresSeleccionados ? edificiosSeleccionadosAscensores : "-"}</td>
            <td>{ascensoresSeleccionados ? ascensoresSeleccionados : "-"}</td>
            <td>
              {ascensoresSeleccionados
                ? audienciaSeleccionadaAscensor.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </td>
            <td>
              {ascensoresSeleccionados && horasFinal
                ? horasFinal.toLocaleString(localString(pais), { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                : "-"}
            </td>
            <td>{ascensoresSeleccionados && plazo ? plazo : "-"}</td>
            <td>
              {salidasAscensores
                ? salidasAscensores.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </td>
            <td>
              {impactosTotalAscensores
                ? impactosTotalAscensores.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </td>
            <td>{ascensoresSeleccionados && duracion ? duracion : "-"}</td>
            <td className="text-center">
              {tarifaFriaAscensores
                ? `${selectedMoneda} ${tarifaFriaAscensores.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}`
                : "-"}
            </td>
            {!isProgrammaticSelected && (
              <td className="text-center">
                {descuentoGlobal && ascensoresSeleccionados && todosLosTotalesCompletos()
                  ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    })}%`
                  : "-"}
              </td>
            )}
            <td className="text-center">
              {inversionAscensoresFinal
                ? `${selectedMoneda} ${inversionAscensoresFinal.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}`
                : "-"}
            </td>
            <td className="text-center">
              {cpmAscensoresFinal
                ? `${selectedMoneda} ${cpmAscensoresFinal.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}`
                : "-"}
            </td>
          </tr>
          {totemsTotal > 0 && (
            <tr>
              <th scope="row" className="text-start">
                CIRCULACIÓN
              </th>
              <td>{totemsSeleccionados ? edificiosSeleccionadosTotems : "-"}</td>
              <td>{totemsSeleccionados ? totemsSeleccionados : "-"}</td>
              <td>
                {totemsSeleccionados
                  ? audienciaSeleccionadaTotems.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>
                {totemsSeleccionados && horasFinal
                  ? horasFinal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>{totemsSeleccionados && plazo ? plazo : "-"}</td>
              <td>
                {salidasTotems
                  ? salidasTotems.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>
                {impactosTotalTotems
                  ? impactosTotalTotems.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>{totemsSeleccionados && duracion ? duracion : "-"}</td>
              <td className="text-center">
                {tarifaFriaTotems
                  ? `${selectedMoneda} ${tarifaFriaTotems.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`
                  : "-"}
              </td>
              {!isProgrammaticSelected && (
                <td className="text-center">
                  {descuentoGlobal && totemsSeleccionados && todosLosTotalesCompletos()
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </td>
              )}
              <td className="text-center">
                {inversionTotemsFinal
                  ? `${selectedMoneda} ${inversionTotemsFinal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`
                  : "-"}
              </td>
              <td className="text-center">
                {cpmTotemsFinal
                  ? `${selectedMoneda} ${cpmTotemsFinal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`
                  : "-"}
              </td>
            </tr>
          )}
          {esperaTotal > 0 && (
            <tr>
              <th scope="row" className="text-start">
                ESPERA
              </th>
              <td>{esperaSeleccionados ? edificiosSeleccionadosEspera : "-"}</td>
              <td>{esperaSeleccionados ? esperaSeleccionados : "-"}</td>
              <td>
                {esperaSeleccionados
                  ? audienciaSeleccionadaEspera.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>
                {esperaSeleccionados && horasFinal
                  ? horasFinal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>{esperaSeleccionados && plazo ? plazo : "-"}</td>
              <td>
                {salidasEspera
                  ? salidasEspera.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>
                {impactosTotalEspera
                  ? impactosTotalEspera.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>{esperaSeleccionados && duracion ? duracion : "-"}</td>
              <td className="text-center">
                {tarifaFriaEspera
                  ? `${selectedMoneda} ${tarifaFriaEspera.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`
                  : "-"}
              </td>
              {!isProgrammaticSelected && (
                <td className="text-center">
                  {descuentoGlobal && esperaSeleccionados && todosLosTotalesCompletos()
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </td>
              )}
              <td className="text-center">
                {inversionEsperaFinal
                  ? `${selectedMoneda} ${inversionEsperaFinal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`
                  : "-"}
              </td>
              <td className="text-center">
                {cpmEsperaFinal
                  ? `${selectedMoneda} ${cpmEsperaFinal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`
                  : "-"}
              </td>
            </tr>
          )}
          {coworkTotal > 0 && (
            <tr>
              <th scope="row" className="text-start">
                COWORK iF
              </th>
              <td>{coworkSeleccionados ? edificiosSeleccionadosCowork : "-"}</td>
              <td>{coworkSeleccionados ? coworkSeleccionados : "-"}</td>
              <td>
                {coworkSeleccionados
                  ? audienciaSeleccionadaCowork.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>
                {coworkSeleccionados && horasFinal
                  ? horasFinal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>{coworkSeleccionados && plazo ? plazo : "-"}</td>
              <td>
                {salidasCowork
                  ? salidasCowork.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>
                {impactosTotalCowork
                  ? impactosTotalCowork.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </td>
              <td>{coworkSeleccionados && duracion ? duracion : "-"}</td>
              <td className="text-center">
                {tarifaFriaCowork
                  ? `${selectedMoneda} ${tarifaFriaCowork.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`
                  : "-"}
              </td>
              {!isProgrammaticSelected && (
                <td className="text-center">
                  {descuentoGlobal && coworkSeleccionados && todosLosTotalesCompletos()
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </td>
              )}
              <td className="text-center">
                {inversionCoworkFinal
                  ? `${selectedMoneda} ${inversionCoworkFinal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`
                  : "-"}
              </td>
              <td className="text-center">
                {cpmCoworkFinal
                  ? `${selectedMoneda} ${cpmCoworkFinal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`
                  : "-"}
              </td>
            </tr>
          )}
          <tr className="table-dark fw-bold">
            <th scope="row" className="text-start">
              TOTALES
            </th>
            <td>
              {ascensoresSeleccionados || totemsSeleccionados || coworkSeleccionados || esperaSeleccionados
                ? edificiosUnicosTotal
                : "-"}
            </td>
            <td>
              {ascensoresSeleccionados || totemsSeleccionados || coworkSeleccionados || esperaSeleccionados
                ? ascensoresSeleccionados + totemsSeleccionados + coworkSeleccionados + esperaSeleccionados
                : "-"}
            </td>
            <td>
              {ascensoresSeleccionados || totemsSeleccionados || coworkSeleccionados || esperaSeleccionados
                ? audienciaSeleccionadaTotal.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </td>
            <td>
              {(ascensoresSeleccionados || totemsSeleccionados || coworkSeleccionados || esperaSeleccionados) &&
              horasFinal
                ? horasFinal.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </td>
            <td>
              {(ascensoresSeleccionados || totemsSeleccionados || coworkSeleccionados || esperaSeleccionados) && plazo
                ? plazo
                : "-"}
            </td>
            <td>
              {(ascensoresSeleccionados || totemsSeleccionados || coworkSeleccionados || esperaSeleccionados) &&
              salidasTotales
                ? salidasTotales.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </td>
            <td>
              {impactosTotalAscensores || impactosTotalTotems || impactosTotalCowork || impactosTotalEspera
                ? impactosTotales.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </td>
            <td>
              {(ascensoresSeleccionados || totemsSeleccionados || coworkSeleccionados || esperaSeleccionados) &&
              duracion
                ? duracion
                : "-"}
            </td>
            <td className="text-center">
              {tarifaFriaTotal
                ? `${selectedMoneda} ${tarifaFriaTotal.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}`
                : "-"}
            </td>
            {!isProgrammaticSelected && (
              <td className="text-center">
                {descuentoGlobal && todosLosTotalesCompletos()
                  ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    })}%`
                  : "-"}
              </td>
            )}
            <td className="text-center">
              {inversionFinalTotal
                ? `${selectedMoneda} ${inversionFinalTotal.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}`
                : "-"}
            </td>
            <td>
              {cpmTotalFinal
                ? `${selectedMoneda} ${cpmTotalFinal.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}`
                : "-"}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="text-center mt-2 button-group">
        <PDFDownloadLink
          className={`btn btn-primary text-white ${todosLosTotalesCompletos() ? "" : "disabled"}`}
          document={
            <PdfCotizacion
              ascensoresSeleccionados={ascensoresSeleccionados}
              audienciaSeleccionadaAscensor={audienciaSeleccionadaAscensor}
              audienciaSeleccionadaCowork={audienciaSeleccionadaCowork}
              audienciaSeleccionadaEspera={audienciaSeleccionadaEspera}
              audienciaSeleccionadaTotal={audienciaSeleccionadaTotal}
              audienciaSeleccionadaTotems={audienciaSeleccionadaTotems}
              codigoGenerado={codigoGenerado}
              coworkSeleccionados={coworkSeleccionados}
              cpmAscensoresFinal={cpmAscensoresFinal}
              cpmCoworkFinal={cpmCoworkFinal}
              cpmEsperaFinal={cpmEsperaFinal}
              cpmTotalFinal={cpmTotalFinal}
              cpmTotemsFinal={cpmTotemsFinal}
              descuentoGlobal={descuentoGlobal}
              duracion={duracion}
              edificiosSeleccionadosAscensores={edificiosSeleccionadosAscensores}
              edificiosSeleccionadosCowork={edificiosSeleccionadosCowork}
              edificiosSeleccionadosEspera={edificiosSeleccionadosEspera}
              edificiosSeleccionadosTotems={edificiosSeleccionadosTotems}
              edificiosUnicosTotal={edificiosUnicosTotal}
              esperaSeleccionados={esperaSeleccionados}
              horas={horas}
              horasFinal={horasFinal}
              impactosTotalAscensores={impactosTotalAscensores}
              impactosTotalCowork={impactosTotalCowork}
              impactosTotalEspera={impactosTotalEspera}
              impactosTotalTotems={impactosTotalTotems}
              impactosTotales={impactosTotales}
              intervalos={intervalos}
              inversionAscensoresFinal={inversionAscensoresFinal}
              inversionCoworkFinal={inversionCoworkFinal}
              inversionEsperaFinal={inversionEsperaFinal}
              inversionFinalTotal={inversionFinalTotal}
              inversionTotemsFinal={inversionTotemsFinal}
              isProgrammaticSelected={isProgrammaticSelected}
              nombresEdificiosNoSeleccionados={nombresEdificiosNoSeleccionados}
              nombresEdificiosSeleccionadosAscensores={nombresEdificiosSeleccionadosAscensores}
              nombresEdificiosSeleccionadosCowork={nombresEdificiosSeleccionadosCowork}
              nombresEdificiosSeleccionadosEspera={nombresEdificiosSeleccionadosEspera}
              nombresEdificiosSeleccionadosTotems={nombresEdificiosSeleccionadosTotems}
              options={options}
              pais={pais}
              plazo={plazo}
              salidasAscensores={salidasAscensores}
              salidasCowork={salidasCowork}
              salidasEspera={salidasEspera}
              salidasTotales={salidasTotales}
              salidasTotems={salidasTotems}
              tarifaFriaAscensores={tarifaFriaAscensores}
              tarifaFriaCowork={tarifaFriaCowork}
              tarifaFriaEspera={tarifaFriaEspera}
              tarifaFriaTotal={tarifaFriaTotal}
              tarifaFriaTotems={tarifaFriaTotems}
              totemsSeleccionados={totemsSeleccionados}
              usuarioApellido={usuarioApellido}
              usuarioNombre={usuarioNombre}
            />
          }
          fileName={`${obtenerFechaActual()}. ${operacionTextual(pais)} - Cotización ${codigoGenerado}.pdf`}
          disabled={!todosLosTotalesCompletos()}
          onClick={handleSaveCotizacion}
        >
          Generar Cotización
        </PDFDownloadLink>
        {canLinkOpportunity && (
          <Button
            color="secondary"
            className={`btn ${todosLosTotalesCompletos() ? "" : "disabled"}`}
            disabled={!todosLosTotalesCompletos()}
            onClick={() => setShowLinkModal(true)}
          >
            Vincular Oportunidad
          </Button>
        )}
        {/* Segundo PDFDownloadLink invisible para la descarga al vincular */}
        {clienteParaDescarga && (
          <PDFDownloadLink
            document={
              <PdfCotizacion
                ascensoresSeleccionados={ascensoresSeleccionados}
                audienciaSeleccionadaAscensor={audienciaSeleccionadaAscensor}
                audienciaSeleccionadaCowork={audienciaSeleccionadaCowork}
                audienciaSeleccionadaEspera={audienciaSeleccionadaEspera}
                audienciaSeleccionadaTotal={audienciaSeleccionadaTotal}
                audienciaSeleccionadaTotems={audienciaSeleccionadaTotems}
                codigoGenerado={codigoGenerado}
                coworkSeleccionados={coworkSeleccionados}
                cpmAscensoresFinal={cpmAscensoresFinal}
                cpmCoworkFinal={cpmCoworkFinal}
                cpmEsperaFinal={cpmEsperaFinal}
                cpmTotalFinal={cpmTotalFinal}
                cpmTotemsFinal={cpmTotemsFinal}
                descuentoGlobal={descuentoGlobal}
                duracion={duracion}
                edificiosSeleccionadosAscensores={edificiosSeleccionadosAscensores}
                edificiosSeleccionadosCowork={edificiosSeleccionadosCowork}
                edificiosSeleccionadosEspera={edificiosSeleccionadosEspera}
                edificiosSeleccionadosTotems={edificiosSeleccionadosTotems}
                edificiosUnicosTotal={edificiosUnicosTotal}
                esperaSeleccionados={esperaSeleccionados}
                horas={horas}
                horasFinal={horasFinal}
                impactosTotalAscensores={impactosTotalAscensores}
                impactosTotalCowork={impactosTotalCowork}
                impactosTotalEspera={impactosTotalEspera}
                impactosTotalTotems={impactosTotalTotems}
                impactosTotales={impactosTotales}
                intervalos={intervalos}
                inversionAscensoresFinal={inversionAscensoresFinal}
                inversionCoworkFinal={inversionCoworkFinal}
                inversionEsperaFinal={inversionEsperaFinal}
                inversionFinalTotal={inversionFinalTotal}
                inversionTotemsFinal={inversionTotemsFinal}
                isProgrammaticSelected={isProgrammaticSelected}
                nombresEdificiosNoSeleccionados={nombresEdificiosNoSeleccionados}
                nombresEdificiosSeleccionadosAscensores={nombresEdificiosSeleccionadosAscensores}
                nombresEdificiosSeleccionadosCowork={nombresEdificiosSeleccionadosCowork}
                nombresEdificiosSeleccionadosEspera={nombresEdificiosSeleccionadosEspera}
                nombresEdificiosSeleccionadosTotems={nombresEdificiosSeleccionadosTotems}
                options={options}
                pais={pais}
                plazo={plazo}
                salidasAscensores={salidasAscensores}
                salidasCowork={salidasCowork}
                salidasEspera={salidasEspera}
                salidasTotales={salidasTotales}
                salidasTotems={salidasTotems}
                tarifaFriaAscensores={tarifaFriaAscensores}
                tarifaFriaCowork={tarifaFriaCowork}
                tarifaFriaEspera={tarifaFriaEspera}
                tarifaFriaTotal={tarifaFriaTotal}
                tarifaFriaTotems={tarifaFriaTotems}
                totemsSeleccionados={totemsSeleccionados}
                usuarioApellido={usuarioApellido}
                usuarioNombre={usuarioNombre}
              />
            }
            fileName={`${obtenerFechaActual()}. ${operacionTextual(pais)} - Cotización ${codigoGenerado} - ${clienteParaDescarga} - ${formData?.campana}.pdf`}
          >
            {({ blob, url, loading, error }) => {
              if (!loading && url) {
                const link = document.createElement("a");
                link.href = url;
                link.download = `${obtenerFechaActual()}. ${operacionTextual(pais)} - Cotización ${codigoGenerado} - ${clienteParaDescarga} - ${formData?.campana}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setShowLinkModal(false);
                toast.success("Oportunidad vinculada correctamente");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
                setClienteParaDescarga(null);
                setFormData(null); // Limpiar el estado después de la descarga
              }
              return null;
            }}
          </PDFDownloadLink>
        )}
      </div>

      <Modal isOpen={showLinkModal} toggle={() => setShowLinkModal(false)}>
        <ModalHeader toggle={() => setShowLinkModal(false)}>Vincular Cotización a Oportunidad</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(handleLinkOportunidad)}>
            <div className="mb-3">
              <label htmlFor="clientePublicitario" className="form-label">
                Cliente Publicitario
              </label>
              <select
                className={`form-control ${errors.clientePublicitario ? "is-invalid" : ""}`}
                id="clientePublicitario"
                {...register("clientePublicitario", { required: "Este campo es obligatorio" })}
              >
                <option value="">Seleccione un cliente</option>
                {renderClientesOptions()}
              </select>
              {errors.clientePublicitario && (
                <div className="invalid-feedback">{errors.clientePublicitario.message}</div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="campana" className="form-label">
                Campaña
              </label>
              <input
                type="text"
                className={`form-control ${errors.campana ? "is-invalid" : ""}`}
                id="campana"
                placeholder="Ej.: PROMO VERANO, 25 caracteres máx."
                maxLength={25}
                {...register("campana", {
                  required: "Este campo es obligatorio",
                  validate: {
                    lastChar: (value) => value.trim() === value || "No debe haber espacios al final",
                  },
                  onChange: (e) => {
                    e.target.value = e.target.value.toUpperCase();
                  },
                })}
              />
              {errors.campana && <div className="invalid-feedback">{errors.campana.message}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="canal" className="form-label">
                Canal
              </label>
              <select
                className={`form-control ${errors.canal ? "is-invalid" : ""}`}
                id="canal"
                {...register("canal", { required: "Este campo es obligatorio" })}
              >
                <option value="">Seleccione un canal</option>
                <option value="B2C">B2C</option>
                <option value="B2B">B2B</option>
                <option value="B2G">B2G</option>
              </select>
              {errors.canal && <div className="invalid-feedback">{errors.canal.message}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="mesCierre" className="form-label">
                Mes de Cierre
              </label>
              <select
                className={`form-control ${errors.mesCierre ? "is-invalid" : ""}`}
                id="mesCierre"
                {...register("mesCierre", { required: "Este campo es obligatorio" })}
              >
                <option value="">Seleccione un mes</option>
                {[
                  { value: "01", label: "ENE" },
                  { value: "02", label: "FEB" },
                  { value: "03", label: "MAR" },
                  { value: "04", label: "ABR" },
                  { value: "05", label: "MAY" },
                  { value: "06", label: "JUN" },
                  { value: "07", label: "JUL" },
                  { value: "08", label: "AGO" },
                  { value: "09", label: "SET" },
                  { value: "10", label: "OCT" },
                  { value: "11", label: "NOV" },
                  { value: "12", label: "DIC" },
                ].map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
              {errors.mesCierre && <div className="invalid-feedback">{errors.mesCierre.message}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="mes" className="form-label">
                Mes de Facturación
              </label>
              <select
                className={`form-control ${errors.mes ? "is-invalid" : ""}`}
                id="mes"
                {...register("mes", { required: "Este campo es obligatorio" })}
              >
                <option value="">Seleccione un mes</option>
                {[
                  { value: "01", label: "ENE" },
                  { value: "02", label: "FEB" },
                  { value: "03", label: "MAR" },
                  { value: "04", label: "ABR" },
                  { value: "05", label: "MAY" },
                  { value: "06", label: "JUN" },
                  { value: "07", label: "JUL" },
                  { value: "08", label: "AGO" },
                  { value: "09", label: "SET" },
                  { value: "10", label: "OCT" },
                  { value: "11", label: "NOV" },
                  { value: "12", label: "DIC" },
                ].map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
              {errors.mes && <div className="invalid-feedback">{errors.mes.message}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="estatus" className="form-label">
                Estatus
              </label>
              <select
                className={`form-control ${errors.estatus ? "is-invalid" : ""}`}
                id="estatus"
                {...register("estatus", { required: "Este campo es obligatorio" })}
              >
                <option value="">Seleccione un estatus</option>
                <option value="100%">100%</option>
                <option value="90%">90%</option>
                <option value="75%">75%</option>
                <option value="50%">50%</option>
              </select>
              {errors.estatus && <div className="invalid-feedback">{errors.estatus.message}</div>}
            </div>
            <ModalFooter>
              <Button color="secondary" onClick={() => setShowLinkModal(false)}>
                Cancelar
              </Button>
              <Button color="primary" type="submit">
                Vincular
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};
