/* eslint-disable indent */
import React, { useCallback, useMemo } from "react";
import screenmediaLogo from "../../img/screenmedia-logo.png";
import visionaLogo from "../../img/visiona-logo.png";
import wecastLogo from "../../img/wecast-logo.png";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import styles from "./stylesPdfCotizacion"; // Importa los estilos desde el archivo separado

const today = new Date();
const month = String(today.getMonth() + 1).padStart(2, "0");
const day = String(today.getDate()).padStart(2, "0");
const date = `${day}-${month}-${today.getFullYear()}`;
const hour = String(today.getHours()).padStart(2, "0");
const minute = String(today.getMinutes()).padStart(2, "0");

const PdfCotizacion = ({
  // descuentoTotalAscensores = 0,
  // descuentoTotalCowork = 0,
  // descuentoTotalFinal = 0,
  // descuentoTotalTotems = 0,
  // edificiosSeleccionadosAscensores = 0,
  // edificiosSeleccionadosTotems = 0,
  // edificiosUnicosTotal = 0,
  // horas,
  ascensoresSeleccionados = 0,
  audienciaSeleccionadaAscensor = 0,
  audienciaSeleccionadaCowork = 0,
  audienciaSeleccionadaEspera = 0,
  audienciaSeleccionadaTotal = 0,
  audienciaSeleccionadaTotems = 0,
  codigoGenerado,
  coworkSeleccionados = 0,
  cpmAscensoresFinal = 0,
  cpmCoworkFinal = 0,
  cpmEsperaFinal = 0,
  cpmTotalFinal = 0,
  cpmTotemsFinal = 0,
  descuentoGlobal = 0,
  duracion = 0,
  esperaSeleccionados = 0,
  horasFinal = 0,
  impactosTotalAscensores = 0,
  impactosTotalCowork = 0,
  impactosTotalEspera = 0,
  impactosTotalTotems = 0,
  impactosTotales = 0,
  intervalos,
  inversionAscensoresFinal = 0,
  inversionCoworkFinal = 0,
  inversionEsperaFinal = 0,
  inversionFinalTotal = 0,
  inversionTotemsFinal = 0,
  isProgrammaticSelected,
  nombresEdificiosNoSeleccionados = [],
  nombresEdificiosSeleccionadosAscensores = [],
  nombresEdificiosSeleccionadosCowork = [],
  nombresEdificiosSeleccionadosEspera = [],
  nombresEdificiosSeleccionadosTotems = [],
  options,
  pais = "Chile",
  plazo = 0,
  salidasAscensores = 0,
  salidasCowork = 0,
  salidasEspera = 0,
  salidasTotales = 0,
  salidasTotems = 0,
  tarifaFriaAscensores = 0,
  tarifaFriaCowork = 0,
  tarifaFriaEspera = 0,
  tarifaFriaTotal = 0,
  tarifaFriaTotems = 0,
  totemsSeleccionados = 0,
  usuarioApellido,
  usuarioNombre,
}) => {
  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const monedaSimbolo = useCallback(
    (pais) =>
      ({
        Chile: "$ ",
        Perú: "S/ ",
        Uruguay: "$ ",
      })[pais] || "CLP ",
    []
  );

  const ciudad = useCallback(
    (pais) =>
      ({
        Chile: "Santiago",
        Perú: "Lima",
        Uruguay: "Montevideo",
      })[pais] || "Lima",
    []
  );

  const operacionTextual = useCallback(
    (pais) =>
      ({
        Chile: "WECAST",
        Perú: "VISIONA",
        Uruguay: "SCREENMEDIA",
      })[pais] || "VISIONA",
    []
  );

  const monedaTextual = useCallback(
    (pais) =>
      ({
        Chile: "CLP",
        Perú: "PEN",
        Uruguay: "URU",
      })[pais] || "CLP",
    []
  );

  const mailTextual = useCallback(
    (pais) =>
      ({
        Chile: "hola@wecast.cl",
        Perú: "hola@visiona.pe",
        Uruguay: "hola@screenmedia.com.uy",
      })[pais] || "hola@visiona.pe",
    []
  );

  const logo = useMemo(
    () =>
      ({
        Chile: wecastLogo,
        Perú: visionaLogo,
        Uruguay: screenmediaLogo,
      })[pais] || visionaLogo,
    [pais]
  );

  // Calcular totales
  const totalPantallas = ascensoresSeleccionados + totemsSeleccionados + coworkSeleccionados + esperaSeleccionados;
  const nombreUsuario = `${usuarioNombre} ${usuarioApellido}`;

  const ordenarIntervalos = (a, b) => {
    const [horaA, minutoA] = a.split(" - ")[0].split(":").map(Number);
    const [horaB, minutoB] = b.split(" - ")[0].split(":").map(Number);

    if (horaA !== horaB) {
      return horaA - horaB;
    }
    return minutoA - minutoB;
  };

  // Función para manejar singular, plural y casos con 0
  const pluralizar = (cantidad, singular, plural) => {
    if (cantidad === 0) return "ningún edificio";
    return cantidad === 1 ? singular : plural;
  };

  // Enhanced content height estimation for exact sizing, removing excess white space
  const estimateContentHeight = () => {
    const rowHeight = 15; // Reduced row height for tighter packing
    const headerHeight = 50; // Approximate height for header and title
    const tableHeight = 120; // Approximate height for the table (5 rows * 24)
    const horarioHeight = 40; // Approximate height for horario section
    const inventoryItems =
      (nombresEdificiosSeleccionadosAscensores.length > 0
        ? 1 + Math.ceil(nombresEdificiosSeleccionadosAscensores.length / 2)
        : 0) +
      (nombresEdificiosSeleccionadosTotems.length > 0
        ? 1 + Math.ceil(nombresEdificiosSeleccionadosTotems.length / 2)
        : 0) +
      (nombresEdificiosSeleccionadosCowork.length > 0
        ? 1 + Math.ceil(nombresEdificiosSeleccionadosCowork.length / 2)
        : 0) +
      (nombresEdificiosNoSeleccionados.length > 0 ? 1 + Math.ceil(nombresEdificiosNoSeleccionados.length / 2) : 0);
    const inventoryHeight = inventoryItems * rowHeight; // Each item takes ~15 units
    const footerHeight = 80; // Approximate height for footer (text + logo + email)

    const totalEstimatedHeight = headerHeight + tableHeight + horarioHeight + inventoryHeight + footerHeight;
    return totalEstimatedHeight; // Use exact height without any cap to eliminate white space
  };

  return (
    <Document>
      <Page
        size={[1200, estimateContentHeight()]}
        orientation="landscape"
        style={[styles.page, { height: estimateContentHeight() }]}
      >
        <View style={[styles.contentContainer, { flex: 1, justifyContent: "flex-start", height: "100%" }]}>
          <Text style={styles.header} fixed>
            ~ CONFIDENCIAL ~
          </Text>
          <Text style={styles.title}>COTIZACIÓN de ESPACIOS PUBLICITARIOS en {operacionTextual(pais)}</Text>
          <Text style={styles.letrachica0}>
            {ciudad(pais)}, {date} @ {hour}:{minute} | Generado por: {nombreUsuario} | Cotización ID: {codigoGenerado}
          </Text>

          {/* Tabla con estilos Bootstrap-like */}
          <View style={[styles.table, { width: "100%" }]}>
            <View
              style={[
                styles.tableRow,
                styles.tableHeaderTipo,
                {
                  backgroundColor: isProgrammaticSelected ? "#006400" : "#FF8C00", // Verde oscuro para programmatic, naranja oscuro para digital
                  alignSelf: "center", // Centra el cuadro dentro del tableRow
                },
              ]}
            >
              <Text>TIPO DE COMPRA: {isProgrammaticSelected ? "PROGRAMMATIC" : "DIGITAL"}</Text>
            </View>

            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={styles.tableCellStart}>CIRCUITO</Text>
              <Text style={styles.tableCell}>PANTALLAS</Text>
              <Text style={styles.tableCell}>AUDIENCIA</Text>
              <Text style={styles.tableCellQuote}>IMPRESIONES</Text>
              <Text style={styles.tableCellQuote}>IMPACTOS</Text>
              <Text style={styles.tableCellQuote}>COTIZACIÓN</Text>
              {!isProgrammaticSelected && (
                <View style={[styles.tableCell, { backgroundColor: "#FF0000" }]}>
                  <Text style={[styles.tableHeaderRed, { backgroundColor: "transparent" }]}>DESCUENTO</Text>
                </View>
              )}
              <View style={[styles.tableCellQuote, { backgroundColor: "#008000" }]}>
                <Text style={[styles.tableHeaderGreen, { backgroundColor: "transparent" }]}>INVERSIÓN</Text>
              </View>
              <View style={[styles.tableCell, styles.lastCell, { backgroundColor: "#0000FF" }]}>
                <Text style={[styles.tableHeaderBlue, { backgroundColor: "transparent" }]}>CPM</Text>
              </View>
            </View>

            {/* Datos de Ascensores */}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellStart, styles.tableData]}>ASCENSORES</Text>
              <Text style={[styles.tableCell, styles.tableData]}>
                {ascensoresSeleccionados ? ascensoresSeleccionados : "-"}
              </Text>
              <Text style={[styles.tableCell, styles.tableData]}>
                {ascensoresSeleccionados
                  ? audienciaSeleccionadaAscensor.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {ascensoresSeleccionados
                  ? salidasAscensores.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {ascensoresSeleccionados
                  ? impactosTotalAscensores.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {ascensoresSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      tarifaFriaAscensores
                        ? tarifaFriaAscensores.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCell, styles.tableData]}>
                  {ascensoresSeleccionados
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </Text>
              )}
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {ascensoresSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      inversionAscensoresFinal
                        ? inversionAscensoresFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
              <Text style={[styles.tableCell, styles.tableData, styles.lastCell]}>
                {ascensoresSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      cpmAscensoresFinal
                        ? cpmAscensoresFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
            </View>

            {/* Datos de Tótems */}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellStart, styles.tableData]}>TÓTEMS</Text>
              <Text style={[styles.tableCell, styles.tableData]}>
                {totemsSeleccionados ? totemsSeleccionados : "-"}
              </Text>
              <Text style={[styles.tableCell, styles.tableData]}>
                {totemsSeleccionados
                  ? audienciaSeleccionadaTotems.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {totemsSeleccionados
                  ? salidasTotems.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {totemsSeleccionados
                  ? impactosTotalTotems.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {totemsSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      tarifaFriaTotems
                        ? tarifaFriaTotems.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCell, styles.tableData]}>
                  {totemsSeleccionados
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </Text>
              )}
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {totemsSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      inversionTotemsFinal
                        ? inversionTotemsFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
              <Text style={[styles.tableCell, styles.tableData, styles.lastCell]}>
                {totemsSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      cpmTotemsFinal
                        ? cpmTotemsFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
            </View>

            {/* Datos de Espera */}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellStart, styles.tableData]}>ESPERA</Text>
              <Text style={[styles.tableCell, styles.tableData]}>
                {esperaSeleccionados ? esperaSeleccionados : "-"}
              </Text>
              <Text style={[styles.tableCell, styles.tableData]}>
                {esperaSeleccionados
                  ? audienciaSeleccionadaEspera.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {esperaSeleccionados
                  ? salidasEspera.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {esperaSeleccionados
                  ? impactosTotalEspera.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {esperaSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      tarifaFriaEspera
                        ? tarifaFriaEspera.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCell, styles.tableData]}>
                  {esperaSeleccionados
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </Text>
              )}
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {esperaSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      inversionEsperaFinal
                        ? inversionEsperaFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
              <Text style={[styles.tableCell, styles.tableData, styles.lastCell]}>
                {esperaSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      cpmEsperaFinal
                        ? cpmEsperaFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
            </View>

            {/* Datos de Cowork */}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellStart, styles.tableData]}>COWORK iF</Text>
              <Text style={[styles.tableCell, styles.tableData]}>
                {coworkSeleccionados ? coworkSeleccionados : "-"}
              </Text>
              <Text style={[styles.tableCell, styles.tableData]}>
                {coworkSeleccionados
                  ? audienciaSeleccionadaCowork.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {coworkSeleccionados
                  ? salidasCowork.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {coworkSeleccionados
                  ? impactosTotalCowork.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {coworkSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      tarifaFriaCowork
                        ? tarifaFriaCowork.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCell, styles.tableData]}>
                  {coworkSeleccionados
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </Text>
              )}
              <Text style={[styles.tableCellQuote, styles.tableData]}>
                {coworkSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      inversionCoworkFinal
                        ? inversionCoworkFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
              <Text style={[styles.tableCell, styles.tableData, styles.lastCell]}>
                {coworkSeleccionados
                  ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                      cpmCoworkFinal
                        ? cpmCoworkFinal.toLocaleString(localString(pais), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : "-"
                    }`
                  : "-"}
              </Text>
            </View>

            {/* Totales */}
            <View style={[styles.tableRow, styles.tableTotal]}>
              <Text style={[styles.tableCellStart, styles.tableDataTotales]}>TOTALES</Text>
              <Text style={[styles.tableCell, styles.tableDataTotales]}>{totalPantallas || "-"}</Text>
              <Text style={[styles.tableCell, styles.tableDataTotales]}>
                {audienciaSeleccionadaTotal.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }) || "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableDataTotales]}>
                {salidasTotales.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }) || "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableDataTotales]}>
                {impactosTotales.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }) || "-"}
              </Text>
              <Text style={[styles.tableCellQuote, styles.tableDataTotales]}>
                {`${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                  tarifaFriaTotal
                    ? tarifaFriaTotal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"
                }`}
              </Text>
              {!isProgrammaticSelected && (
                <Text style={[styles.tableCell, styles.tableDataTotales]}>
                  {descuentoGlobal
                    ? `${(descuentoGlobal * 100).toLocaleString(localString(pais), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      })}%`
                    : "-"}
                </Text>
              )}
              <Text style={[styles.tableCellQuote, styles.tableDataTotales]}>
                {`${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                  inversionFinalTotal
                    ? inversionFinalTotal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"
                }`}
              </Text>
              <Text style={[styles.tableCell, styles.tableDataTotales, styles.lastCell]}>
                {`${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                  cpmTotalFinal
                    ? cpmTotalFinal.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "-"
                }`}
              </Text>
            </View>
          </View>
          <View style={styles.hr} />
          <View style={styles.horasFinal}>
            <Text style={styles.horasFinalTitulo}>
              EXHIBICIÓN DIARIA: <Text style={styles.horasFinaltext}>{horasFinal || "-"} horas</Text>
            </Text>
          </View>
          <View style={styles.plazo}>
            <Text style={styles.plazoTitulo}>
              PLAZO DE PAUTA: <Text style={styles.plazotext}>{plazo || "-"} días</Text>{" "}
            </Text>
          </View>
          <View style={styles.duracion}>
            <Text style={styles.duracionTitulo}>
              DURACIÓN DEL CONTENIDO: <Text style={styles.duraciontext}>{duracion || "-"} segundos</Text>{" "}
            </Text>
          </View>
          <View style={styles.horario}>
            <Text style={styles.horarioTitulo}>
              HORARIO DE PAUTA:{" "}
              {isProgrammaticSelected ? (
                <Text style={styles.horariotext}>
                  Intervalos:{" "}
                  {intervalos.length > 0
                    ? intervalos.sort(ordenarIntervalos).join(" | ")
                    : "No hay intervalos seleccionados"}
                </Text>
              ) : (
                <Text style={styles.horariotext}>
                  {options.length > 0 ? options[0].label : "No hay opciones seleccionadas"}
                </Text>
              )}
            </Text>
          </View>
          <View style={styles.inventario}>
            <Text style={styles.inventarioTitulo}>INVENTARIO:</Text>
            {/* Circuito Ascensores */}
            <Text style={[styles.inventariosubTitulo, { marginLeft: 20 }]}>
              {" "}
              {/* Add left margin for hierarchy */}Circuito Ascensores |{" "}
              {nombresEdificiosSeleccionadosAscensores.length > 0
                ? `${nombresEdificiosSeleccionadosAscensores.length} ${pluralizar(
                    nombresEdificiosSeleccionadosAscensores.length,
                    "edificio seleccionado:",
                    "edificios seleccionados:"
                  )}`
                : "Ningún edificio seleccionado"}
            </Text>
            {nombresEdificiosSeleccionadosAscensores.length > 0 && (
              <Text style={[styles.inventarioNombres, { marginLeft: 40, width: "100%" }]}>
                {" "}
                {/* Add left margin for tab effect */}
                {nombresEdificiosSeleccionadosAscensores
                  .join(" • ")
                  .match(/.{1,140}(?=\s|•|$)/g)
                  ?.join("\n") || ""}
              </Text>
            )}

            {pais !== "Perú" && (
              <>
                {/* Circuito Circulación */}
                <Text style={[styles.inventariosubTitulo, { marginLeft: 20 }]}>
                  {" "}
                  {/* Add left margin for hierarchy */}Circuito Circulación |{" "}
                  {nombresEdificiosSeleccionadosTotems.length > 0
                    ? `${nombresEdificiosSeleccionadosTotems.length} ${pluralizar(
                        nombresEdificiosSeleccionadosTotems.length,
                        "edificio seleccionado:",
                        "edificios seleccionados:"
                      )}`
                    : "Ningún edificio seleccionado"}
                </Text>
                {nombresEdificiosSeleccionadosTotems.length > 0 && (
                  <Text style={[styles.inventarioNombres, { marginLeft: 40, width: "100%" }]}>
                    {" "}
                    {/* Add left margin for tab effect */}
                    {nombresEdificiosSeleccionadosTotems
                      .join(" • ")
                      .match(/.{1,140}(?=\s|•|$)/g)
                      ?.join("\n") || ""}
                  </Text>
                )}
                {/* Circuito Espera */}
                <Text style={[styles.inventariosubTitulo, { marginLeft: 20 }]}>
                  {" "}
                  {/* Add left margin for hierarchy */}Circuito Espera |{" "}
                  {nombresEdificiosSeleccionadosEspera.length > 0
                    ? `${nombresEdificiosSeleccionadosEspera.length} ${pluralizar(
                        nombresEdificiosSeleccionadosEspera.length,
                        "edificio seleccionado:",
                        "edificios seleccionados:"
                      )}`
                    : "Ningún edificio seleccionado"}
                </Text>
                {nombresEdificiosSeleccionadosEspera.length > 0 && (
                  <Text style={[styles.inventarioNombres, { marginLeft: 40, width: 1160 }]}>
                    {" "}
                    {/* Add left margin for tab effect */}
                    {nombresEdificiosSeleccionadosEspera
                      .join(" • ")
                      .match(/.{1,140}(?=\s|•|$)/g)
                      ?.join("\n") || ""}
                  </Text>
                )}
                {/* Circuito Cowork iF */}
                <Text style={[styles.inventariosubTitulo, { marginLeft: 20 }]}>
                  {" "}
                  {/* Add left margin for hierarchy */}Circuito Cowork iF |{" "}
                  {nombresEdificiosSeleccionadosCowork.length > 0
                    ? `${nombresEdificiosSeleccionadosCowork.length} ${pluralizar(
                        nombresEdificiosSeleccionadosCowork.length,
                        "ubicación seleccionada:",
                        "ubicaciones seleccionadas:"
                      )}`
                    : "Ningún edificio seleccionado"}
                </Text>
                {nombresEdificiosSeleccionadosCowork.length > 0 && (
                  <Text style={[styles.inventarioNombres, { marginLeft: 40, width: "100%" }]}>
                    {" "}
                    {/* Add left margin for tab effect */}
                    {nombresEdificiosSeleccionadosCowork
                      .join(" • ")
                      .match(/.{1,140}(?=\s|•|$)/g)
                      ?.join("\n") || ""}
                  </Text>
                )}
              </>
            )}
            {/* Edificios No Seleccionados */}
            <Text style={[styles.inventariosubTitulo, { marginLeft: 20 }]}>
              {" "}
              {/* Add left margin for hierarchy */}Edificios no seleccionados:
            </Text>
            {nombresEdificiosNoSeleccionados && nombresEdificiosNoSeleccionados.length > 0 ? (
              <Text style={[styles.inventarioNombresLast, { marginLeft: 40, width: "100%" }]}>
                {" "}
                {/* Add left margin for tab effect */}
                {nombresEdificiosNoSeleccionados
                  .join(" • ")
                  .match(/.{1,140}(?=\s|•|$)/g)
                  ?.join("\n") || ""}
              </Text>
            ) : (
              <Text style={[styles.inventarioNombresLast, { marginLeft: 40, width: "100%" }]}>
                Todos los edificios fueron seleccionados
              </Text>
            )}
          </View>
          <View style={styles.hr} />

          <View style={[styles.footer, { marginTop: 0 }]}>
            <Text style={styles.letrachica2}>
              Propuesta exclusiva para agencia y/o cliente cotizado. Importes expresados en{" "}
              {isProgrammaticSelected ? "US$" : `${monedaTextual(pais)}`} y no incluyen impuestos. Exhibición de lunes a
              viernes de 7 AM a 9 PM según frecuencia y horario contratado. Propuesta incluye descuentos especiales que
              no podrán considerarse como antecedente. El cliente podrá cambiar materiales tantas veces como desee.
              Cotización válida para pautas a emitirse hasta diciembre 2025 inclusive.
            </Text>
            <Image style={styles.image} src={logo} />
            <Text style={styles.mail}>{mailTextual(pais)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfCotizacion;
