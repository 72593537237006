import { log, supabase } from ".";

export const updateOportunidad = async ({ id, oportunidad }) => {
  try {
    const data = await supabase.update("sales", oportunidad, id);

    if (!data || data.length === 0) throw new Error("Empty response");

    return data;
  } catch (error) {
    log.error("updateOportunidad", error);
    throw error;
  }
};
