/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthContext } from "../../context/auth";
import { getIntermediarios } from "../../utils";
import "./stylesListadoFacturas.scss";

export const ListadoFacturas = ({
  facturas,
  pantallasEdificio,
  comisionEdificio_1_porcentaje,
  comisionEdificio_1_fechainicio,
  comisionEdificio_1_fechafin,
  comisionEdificio_2_porcentaje,
  comisionEdificio_2_fechainicio,
  comisionEdificio_2_fechafin,
  comisionEdificio_3_porcentaje,
  comisionEdificio_3_fechainicio,
  comisionEdificio_3_fechafin,
}) => {
  const [intermediarios, setIntermediarios] = useState([]);
  const [orden] = useState({ campo: "fechafactura", ascendente: true });
  const [totales, setTotales] = useState({
    facturado: 0,
    comision: 0,
    comisionCobrada: 0,
    totalImporte: 0,
    totalComisionIntermediario: 0,
  });

  const { pais } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$ ",
        Perú: "S/ ",
        Uruguay: "$ ",
      })[pais] || "$ ",
    []
  );

  const decimales = useCallback(
    (pais) =>
      ({
        Chile: 0,
        Perú: 2,
        Uruguay: 0,
      })[pais] || 0,
    []
  );

  useEffect(() => {
    const fetchIntermediarios = async () => {
      const intermediariosData = await getIntermediarios();
      setIntermediarios(intermediariosData);
    };

    fetchIntermediarios();
  }, []);

  const getFacturadoYComisionNuevo = useCallback(
    (importe, intermediarioNombre, pantallasEdificio, pantallastotales, facturafecha) => {
      let comisionEdificioPorcentaje;

      const fechaInicio1 = new Date(comisionEdificio_1_fechainicio);
      const fechaFin1 = new Date(comisionEdificio_1_fechafin);
      const fechaInicio2 = new Date(comisionEdificio_2_fechainicio);
      const fechaFin2 = new Date(comisionEdificio_2_fechafin);
      const fechaInicio3 = new Date(comisionEdificio_3_fechainicio);
      const fechaFin3 = new Date(comisionEdificio_3_fechafin);

      // Determinar qué comisión del edificio se aplica según la fecha de la factura
      if (facturafecha >= fechaInicio1 && facturafecha <= fechaFin1) {
        comisionEdificioPorcentaje = comisionEdificio_1_porcentaje;
      } else if (facturafecha >= fechaInicio2 && facturafecha <= fechaFin2) {
        comisionEdificioPorcentaje = comisionEdificio_2_porcentaje;
      } else if (facturafecha >= fechaInicio3 && facturafecha <= fechaFin3) {
        comisionEdificioPorcentaje = comisionEdificio_3_porcentaje;
      } else {
        comisionEdificioPorcentaje = 0;
      }

      // Buscar el intermediario y determinar su comisión (considerando comision_2)
      const intermediarioEncontrado = intermediarios.find((i) => i.nombre === intermediarioNombre && i.pais === pais);
      let comisionIntermediario = 0;
      if (intermediarioEncontrado) {
        const anoFactura = facturafecha.getFullYear();
        if (
          intermediarioEncontrado.comision_2 !== null &&
          intermediarioEncontrado.comision_2_fechainicio &&
          intermediarioEncontrado.comision_2_fechafin
        ) {
          const fechaInicioIntermediario = new Date(intermediarioEncontrado.comision_2_fechainicio);
          const fechaFinIntermediario = new Date(intermediarioEncontrado.comision_2_fechafin);
          const anoInicioIntermediario = fechaInicioIntermediario.getFullYear();
          const anoFinIntermediario = fechaFinIntermediario.getFullYear();

          comisionIntermediario =
            anoFactura >= anoInicioIntermediario && anoFactura <= anoFinIntermediario
              ? intermediarioEncontrado.comision_2 || 0
              : intermediarioEncontrado.comision || 0;
        } else {
          comisionIntermediario = intermediarioEncontrado.comision || 0;
        }
      }

      // Calcular la comisión del intermediario
      const comisionIntermediarioValor = importe * (comisionIntermediario / 100);
      const inversionNeta = importe - comisionIntermediarioValor;
      const participacionEdificioPorcentaje = pantallasEdificio / pantallastotales;
      const participacionEdificioPlata = participacionEdificioPorcentaje * inversionNeta;
      const comisionEdificio = participacionEdificioPlata * (comisionEdificioPorcentaje / 100);

      return {
        facturado: participacionEdificioPlata,
        comision: comisionEdificio,
        comisionIntermediarioValor: participacionEdificioPorcentaje * comisionIntermediarioValor,
        comisionIntermediarioPorcentaje: comisionIntermediario,
      };
    },
    [
      intermediarios,
      comisionEdificio_1_porcentaje,
      comisionEdificio_1_fechainicio,
      comisionEdificio_1_fechafin,
      comisionEdificio_2_porcentaje,
      comisionEdificio_2_fechainicio,
      comisionEdificio_2_fechafin,
      comisionEdificio_3_porcentaje,
      comisionEdificio_3_fechainicio,
      comisionEdificio_3_fechafin,
      pais,
    ]
  );

  const facturasOrdenadas = useMemo(
    () =>
      [...facturas].sort((a, b) => {
        const fechaA = new Date(a.facturafecha);
        const fechaB = new Date(b.facturafecha);
        return orden.ascendente ? fechaA - fechaB : fechaB - fechaA;
      }),
    [facturas, orden.ascendente]
  );

  useEffect(() => {
    const newTotales = {
      facturado: 0,
      comision: 0,
      comisionCobrada: 0,
      totalImporte: 0,
      totalComisionIntermediario: 0,
    };
    facturasOrdenadas.forEach(({ importe, intermediario, pantallastotales, facturafechacobro, facturafecha }) => {
      const { facturado, comision, comisionIntermediarioValor } = getFacturadoYComisionNuevo(
        importe,
        intermediario,
        pantallasEdificio,
        pantallastotales,
        new Date(facturafecha)
      );

      newTotales.facturado += facturado;
      newTotales.comision += comision;
      newTotales.totalImporte += (pantallasEdificio / pantallastotales) * importe;
      newTotales.totalComisionIntermediario += comisionIntermediarioValor;

      if (facturafechacobro) {
        newTotales.comisionCobrada += comision;
      }
    });

    setTotales(newTotales);
  }, [facturasOrdenadas, getFacturadoYComisionNuevo, pantallasEdificio]);

  return (
    <div className="table-responsive">
      <table className="table table-fixed table-striped table-bordered table-hover">
        {/* Agregar colgroup para definir los anchos de las columnas */}
        <colgroup>
          <col style={{ width: "6%" }} /> {/* NÚMERO FACTURA */}
          <col style={{ width: "7%" }} /> {/* FECHA FACTURA */}
          <col style={{ width: "18%" }} /> {/* CLIENTE */}
          <col style={{ width: "18%" }} /> {/* INTERMEDIARIO */}
          <col style={{ width: "8%" }} /> {/* EDIFICIOS PARTICIPANTES */}
          <col style={{ width: "8%" }} /> {/* PARTICIPACIÓN EDIFICIO */}
          <col style={{ width: "8%" }} /> {/* IMPORTE BRUTO */}
          <col style={{ width: "4%" }} /> {/* COMISIÓN (%) */}
          <col style={{ width: "8%" }} /> {/* IMPORTE NETO */}
          <col style={{ width: "4%" }} /> {/* COMISIÓN EDIFICIO (%) */}
          <col style={{ width: "8%" }} /> {/* COMISIÓN EDIFICIO (IMPORTE) */}
          <col style={{ width: "8%" }} /> {/* COBRADA */}
        </colgroup>

        <thead className="text-center align-middle">
          <tr>
            <th className="numero-factura">NÚMERO FACTURA</th>
            <th className="fecha-factura">FECHA FACTURA</th>
            <th className="cliente">CLIENTE</th>
            <th className="intermediario">INTERMEDIARIO</th>
            <th className="edificios-participantes">EDIFICIOS PARTICIPANTES</th>
            <th className="pantallas-involucradas">PARTICIPACIÓN EDIFICIO</th>
            <th className="importe-bruto">IMPORTE BRUTO</th>
            <th className="comision-intermediario">COMISIÓN</th>
            <th className="importe-neto">IMPORTE NETO</th>
            <th className="comision-edificio" colSpan="2">
              COMISIÓN EDIFICIO
            </th>
            <th className="cobrada">COBRADA</th>
          </tr>
        </thead>

        <tbody>
          {facturasOrdenadas.map(
            ({
              cliente,
              facturafecha,
              facturafechacobro,
              facturanumero,
              id,
              importe,
              intermediario,
              pantallastotales,
              edificiosUnicos,
            }) => {
              const { facturado, comision, comisionIntermediarioPorcentaje } = getFacturadoYComisionNuevo(
                importe,
                intermediario,
                pantallasEdificio,
                pantallastotales,
                new Date(facturafecha)
              );

              return (
                <tr className="table-striped" key={id}>
                  <td className="text-center">{facturanumero}</td>
                  <td className="text-center">
                    {`${new Date(facturafecha).getUTCDate().toString().padStart(2, "0")}/${(new Date(facturafecha).getUTCMonth() + 1).toString().padStart(2, "0")}/${new Date(facturafecha).getUTCFullYear().toString().slice(-2)}`}
                  </td>
                  <td className="text-center">{cliente}</td>
                  <td className="text-center">{intermediario}</td>
                  <td className="text-center">{edificiosUnicos}</td>
                  <td className="text-center">
                    {Number(((pantallasEdificio / pantallastotales) * 100).toFixed(1)).toLocaleString(
                      localString(pais),
                      {
                        minimumFractionDigits: 1,
                      }
                    )}
                    %
                  </td>
                  <td className="text-end">{`${moneda(pais)} ${(
                    (pantallasEdificio / pantallastotales) *
                    importe
                  ).toLocaleString(localString(pais), {
                    minimumFractionDigits: decimales(pais),
                    maximumFractionDigits: decimales(pais),
                  })}`}</td>
                  <td className="text-center">
                    {comisionIntermediarioPorcentaje > 0 ? `${comisionIntermediarioPorcentaje}%` : "-"}
                  </td>
                  <td className="text-end">{`${moneda(pais)} ${facturado.toLocaleString(localString(pais), {
                    minimumFractionDigits: decimales(pais),
                    maximumFractionDigits: decimales(pais),
                  })}`}</td>
                  <td className="text-center">
                    {(() => {
                      const fechaFactura = new Date(facturafecha);
                      const fechaInicio1 = new Date(comisionEdificio_1_fechainicio);
                      const fechaFin1 = new Date(comisionEdificio_1_fechafin);
                      const fechaInicio2 = new Date(comisionEdificio_2_fechainicio);
                      const fechaFin2 = new Date(comisionEdificio_2_fechafin);
                      const fechaInicio3 = new Date(comisionEdificio_3_fechainicio);
                      const fechaFin3 = new Date(comisionEdificio_3_fechafin);

                      if (fechaFactura >= fechaInicio1 && fechaFactura <= fechaFin1) {
                        return `${comisionEdificio_1_porcentaje}%`;
                      } else if (fechaFactura >= fechaInicio2 && fechaFactura <= fechaFin2) {
                        return `${comisionEdificio_2_porcentaje}%`;
                      } else if (fechaFactura >= fechaInicio3 && fechaFactura <= fechaFin3) {
                        return `${comisionEdificio_3_porcentaje}%`;
                      } else {
                        return "0%";
                      }
                    })()}
                  </td>
                  <td className="text-end">{`${moneda(pais)} ${comision.toLocaleString(localString(pais), {
                    minimumFractionDigits: decimales(pais),
                    maximumFractionDigits: decimales(pais),
                  })}`}</td>
                  <td
                    className="text-center"
                    style={{ backgroundColor: facturafechacobro ? "#198754" : "#dc3545", color: "white" }}
                  >
                    {facturafechacobro
                      ? `${new Date(facturafechacobro).getUTCDate().toString().padStart(2, "0")}/${(new Date(facturafechacobro).getUTCMonth() + 1).toString().padStart(2, "0")}/${new Date(facturafechacobro).getUTCFullYear().toString().slice(-2)}`
                      : "Pendiente"}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
        <tfoot>
          <tr className="table-dark">
            <td colSpan="6" className="text-start">
              TOTALES DEL PERÍODO SELECCIONADO
            </td>
            <td className="text-end">
              {moneda(pais)}
              {(totales.totalImporte || 0).toLocaleString(localString(pais), {
                maximumFractionDigits: decimales(pais),
                minimumFractionDigits: decimales(pais),
              })}
            </td>
            <td className="text-center">
              {100 -
                ((totales.facturado / totales.totalImporte) * 100).toLocaleString(localString(pais), {
                  maximumFractionDigits: decimales(pais),
                  minimumFractionDigits: decimales(pais),
                })}
              %
            </td>
            <td className="text-end">
              {moneda(pais)}
              {(totales.facturado || 0).toLocaleString(localString(pais), {
                maximumFractionDigits: decimales(pais),
                minimumFractionDigits: decimales(pais),
              })}
            </td>
            <td className="text-center">
              {totales.facturado
                ? (() => {
                    const porcentaje = (totales.comision / totales.facturado) * 100;
                    const decimales = porcentaje.toFixed(1) % 1 === 0 ? 0 : 1;
                    return porcentaje.toLocaleString(localString(pais), {
                      maximumFractionDigits: decimales,
                      minimumFractionDigits: decimales,
                    });
                  })()
                : "0"}
              %
            </td>
            <td className="text-end">
              {moneda(pais)}
              {(totales.comision || 0).toLocaleString(localString(pais), {
                maximumFractionDigits: decimales(pais),
                minimumFractionDigits: decimales(pais),
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {(totales.comisionCobrada || 0).toLocaleString(localString(pais), {
                maximumFractionDigits: decimales(pais),
                minimumFractionDigits: decimales(pais),
              })}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
