import React, { useState } from "react";
import "./stylesCotizadorProgrammaticFrecuencia.scss";

export const CotizadorProgrammaticFrecuencia = ({
  onFrecuenciaChange,
  isProgrammaticSelected,
  isDigitalSelected,
  selectedOption,
  plazo,
}) => {
  const [selectedPaquete, setSelectedPaquete] = useState("");

  const handlePaqueteChange = (event) => {
    setSelectedPaquete(event.target.value);
    onFrecuenciaChange(event.target.value);
  };

  // Determinar si una frecuencia está recomendada según inventario, opción seleccionada y plazo
  const isRecommended = (value) => {
    // No mostrar recomendaciones si no se ha seleccionado un inventario
    if (!isProgrammaticSelected && !isDigitalSelected) {
      return false;
    }

    // No mostrar recomendaciones si no se ha seleccionado un plazo
    if (plazo === 0) {
      return false;
    }

    // Programmatic o plazo menor a 5 días: 25 y 30 recomendados
    if (isProgrammaticSelected || plazo < 5) {
      return ["25", "30"].includes(value);
    }

    // Digital seleccionado
    if (isDigitalSelected) {
      // No mostrar recomendaciones si no se ha seleccionado un horario
      if (!selectedOption) {
        return false;
      }

      const isFullDay = selectedOption === "FULL DAY | 07:00 - 21:00";
      const isAmOrPm = selectedOption === "AM | 07:00 - 14:00" || selectedOption === "PM | 14:00 - 21:00";

      if (plazo >= 20) {
        // 1 mes o más (20 días o más)
        if (isFullDay) return value === "5"; // 5 recomendado para Full Day
        if (isAmOrPm) return value === "10"; // 10 recomendado para AM o PM
      } else if (plazo === 10) {
        // 2 semanas (10 días)
        if (isFullDay) return value === "10"; // 10 recomendado para Full Day
        if (isAmOrPm) return value === "15"; // 15 recomendado para AM o PM
      } else if (plazo === 5) {
        // 1 semana (5 días)
        if (isFullDay) return value === "15"; // 15 recomendado para Full Day
        if (isAmOrPm) return value === "20"; // 20 recomendado para AM o PM
      }
    }

    return false; // Ninguna recomendación si no se cumple ninguna condición
  };

  return (
    <div className="frecuencia">
      <p className="titulofrecuencia">FRECUENCIA</p>
      <hr />
      <p className="subtitulo">
        SALIDAS POR HORA <br /> POR PANTALLA
      </p>
      <hr />
      <label className="checkboxfrecuencia">
        <input
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          checked={selectedPaquete === "5"}
          name="paquete"
          onChange={handlePaqueteChange}
          type="checkbox"
          value="5"
        />
        5 {isRecommended("5") && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          checked={selectedPaquete === "10"}
          name="paquete"
          onChange={handlePaqueteChange}
          type="checkbox"
          value="10"
        />
        10 {isRecommended("10") && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          checked={selectedPaquete === "15"}
          name="paquete"
          onChange={handlePaqueteChange}
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          type="checkbox"
          value="15"
        />
        15 {isRecommended("15") && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          checked={selectedPaquete === "20"}
          name="paquete"
          onChange={handlePaqueteChange}
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          type="checkbox"
          value="20"
        />
        20 {isRecommended("20") && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          checked={selectedPaquete === "25"}
          name="paquete"
          onChange={handlePaqueteChange}
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          type="checkbox"
          value="25"
        />
        25 {isRecommended("25") && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          checked={selectedPaquete === "30"}
          name="paquete"
          onChange={handlePaqueteChange}
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          type="checkbox"
          value="30"
        />
        30 {isRecommended("30") && "(recomendado)"}
      </label>
      <br />
    </div>
  );
};
