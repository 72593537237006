/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import cx from "classix";
import { Container } from "../../components";
import { useAuthContext } from "../../context/auth";
import { log, quitarCaracteresEspeciales, saveOportunidadTD } from "../../utils";
import "./stylesTransformacionDigital.scss";

export const TransformacionDigital = () => {
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [temporalDataForm, setTemporalDataForm] = useState();
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const { pais, id } = useAuthContext();

  const methods = useForm();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = methods;

  // Watch the empresa field to apply transformations
  const empresaValue = watch("empresa");

  // Only trim trailing spaces, allow spaces between words
  useEffect(() => {
    if (empresaValue) {
      // Only trim trailing spaces, preserve spaces between words
      const formattedValue = empresaValue.replace(/\s+$/, "");
      setValue("empresa", formattedValue);
    }
  }, [empresaValue, setValue]);

  const onSubmit = useCallback(
    (data) => {
      setShowModalConfirmacion(true);

      // Map estado_oportunidad to a numeric status
      const statusMap = {
        "1. Prospect": 1,
        "2. Lead": 2,
        "3. Contacto": 3,
        "4. Reunión": 4,
        "5. Proyecto": 5,
        "6. Seguimiento": 6,
        "7. Cerrado: ganado": 7,
        "8. Cerrado: perdido": 8,
        "9. Pospuesto": 9,
        "No interesa": 10,
        "No Procede": 11,
      };

      const newOportunidad = {
        empresa: quitarCaracteresEspeciales(data.empresa), // text
        pais, // text
        status: statusMap[data.estado_oportunidad], // numeric
        monto: Number(data.monto), // numeric
        producto: data.producto_servicio, // text
        plazo: Number(data.duracion_contrato), // numeric
        ejecutivo: id, // numeric
      };

      setTemporalDataForm(newOportunidad);
    },
    [pais, id]
  );

  const saveButtonHandle = useCallback(async () => {
    try {
      setShowModalConfirmacion(false);
      await saveOportunidadTD(temporalDataForm);
      reset();
      toast.success("La oportunidad se guardó correctamente");
      setTimeout(() => {
        setShouldRefresh(true);
      }, 2000);
    } catch (error) {
      toast.error("Hubo un error al guardar la oportunidad, prueba de nuevo.");
      log.error("saveButtonHandle", error);
    }
  }, [temporalDataForm, reset]);

  const disableSubmit = useMemo(() => showModalConfirmacion, [showModalConfirmacion]);

  useEffect(() => {
    if (shouldRefresh) {
      window.location.reload();
    }
  }, [shouldRefresh]);

  return (
    <Container className="CargarOportunidad" header titulo="TRANSFORMACIÓN DIGITAL">
      <div className="text-center mb-4">
        <h2 className="h4">Carga Oportunidad</h2>
      </div>
      <FormProvider {...methods}>
        <form className="row g-4 mt-2 h-100" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-3 text-start fw-bold">
            <input
              className={cx("form-control", errors?.empresa && "is-invalid")}
              id="empresa"
              placeholder="EMPRESA"
              type="text"
              {...register("empresa", { required: true })}
            />
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>

          <div className="col-3 text-start fw-bold">
            <select
              className={cx("form-select", errors?.rubro && "is-invalid")}
              id="rubro"
              {...register("rubro", { required: true })}
            >
              <option value="" hidden>
                RUBRO
              </option>
              <option value="CENTROCOMERCIAL">CENTRO COMERCIAL</option>
              <option value="CLINICAS">CLÍNICAS</option>
              <option value="EDIFICIOS">EDIFICIOS</option>
              <option value="HOTELES">HOTELES</option>
              <option value="PDV">PDV</option>
              <option value="MINERA">MINERA</option>
            </select>
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>

          <div className="col-3 text-start fw-bold">
            <select
              className={cx("form-select", errors?.estado_oportunidad && "is-invalid")}
              id="estado_oportunidad"
              {...register("estado_oportunidad", { required: true })}
            >
              <option value="" hidden>
                ESTADO DE LA OFERTA
              </option>
              <option value="1. Prospecto">1. Prospecto</option>
              <option value="2. Lead">2. Lead</option>
              <option value="3. Contacto">3. Contacto</option>
              <option value="4. Reunión">4. Reunión</option>
              <option value="5. Proyecto">5. Proyecto</option>
              <option value="6. Seguimiento">6. Seguimiento</option>
              <option value="7. Cerrado: ganado">7. Cerrado: ganado</option>
              <option value="8. Cerrado: perdido">8. Cerrado: perdido</option>
              <option value="9. Pospuesto">9. Pospuesto</option>
              <option value="No interesa">No interesa</option>
              <option value="No Procede">No Procede</option>
            </select>
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>

          <div className="col-3 text-start fw-bold">
            <input
              className={cx("form-control", errors?.monto && "is-invalid")}
              id="monto"
              placeholder="MONTO OPORTUNIDAD ($)"
              type="number"
              step="0.01"
              {...register("monto", { required: true, min: 0 })}
            />
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>

          <div className="col-3 text-start fw-bold">
            <input
              className={cx("form-control", errors?.producto_servicio && "is-invalid")}
              id="producto_servicio"
              placeholder="PRODUCTO/SERVICIO"
              type="text"
              {...register("producto_servicio", { required: true })}
            />
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>

          <div className="col-3 text-start fw-bold">
            <input
              className={cx("form-control", errors?.duracion_contrato && "is-invalid")}
              id="duracion_contrato"
              placeholder="DURACIÓN CONTRATO (MESES)"
              type="number"
              min="1"
              {...register("duracion_contrato", { required: true, min: 1 })}
            />
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div className="col-2 d-flex align-items-end justify-content-center">
              <button className="btn btn-primary" type="submit" disabled={disableSubmit}>
                {!disableSubmit ? (
                  "GUARDAR OPORTUNIDAD"
                ) : (
                  <>
                    <Spinner size="sm">GUARDANDO...</Spinner>
                    <span> GUARDANDO</span>
                  </>
                )}
              </button>
            </div>
          </div>

          <Modal isOpen={showModalConfirmacion} toggle={() => setShowModalConfirmacion(false)}>
            <ModalHeader>¿Está seguro?</ModalHeader>
            <ModalBody>Se está por guardar una nueva oportunidad</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setShowModalConfirmacion(false)}>
                Cancelar
              </Button>
              <Button color="primary" onClick={saveButtonHandle}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
        </form>
      </FormProvider>
    </Container>
  );
};
