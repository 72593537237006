/* eslint-disable unicorn/prefer-module */
/* eslint-disable no-console */
import { useMemo } from "react";
import { BsBuildingCheck, BsFileSpreadsheet, BsListColumnsReverse, BsFillPinMapFill } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { GiChart, GiSouthAmerica } from "react-icons/gi";
import { HiOutlineUsers } from "react-icons/hi2";
import { IoBusiness } from "react-icons/io5";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { LuClipboardList } from "react-icons/lu";
import { MdOutlineCategory, MdDoNotDisturbAlt } from "react-icons/md";
import { PiListPlusLight, PiSuitcaseSimpleThin } from "react-icons/pi";
import { SiAffinitydesigner } from "react-icons/si";
import { TbUserDollar } from "react-icons/tb";
import { TfiMoney } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { Loader } from "../../components";
import { ROLES } from "../../constants/roles";
import { useAuthContext } from "../../context/auth";
import { logout } from "../../utils";
import "./stylesAdmin.scss";

export const Admin = () => {
  const { pais, session, nombre, rol } = useAuthContext();

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  if (session === undefined) return <Loader fullPage />;

  const nombreUsuario = nombre || "usuario";

  const renderSection = (title, links) => (
    <div className="section">
      <h2 className="section-title">{title}</h2>
      <div className="botones row align-middle">
        {links.map((link, index) => (
          <div key={index} className="col-12 col-md-6 col-lg-3 mb-3">
            <Link to={link.path} className="link">
              {link.icon}
              <span>{link.text}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );

  const getHerramientasUtilitarias = () => {
    // Solo roles que no sean BOARD o EDIFICIO tendrán herramientas utilitarias
    if (rol === ROLES.BOARD || rol === ROLES.EDIFICIO) {
      return [];
    }
    return [
      { path: "/inventario", icon: <IoBusiness className="icon" size={55} />, text: "Inventario" },
      { path: "/mediakit", icon: <SiAffinitydesigner className="icon" size={55} />, text: "Mediakit" },
      { path: "/portafolio", icon: <PiSuitcaseSimpleThin className="icon" size={55} />, text: "Portafolio" },
      { path: "/mapa", icon: <BsFillPinMapFill className="icon" size={55} />, text: "Mapa" },
      {
        path: "/listadoempresas",
        icon: <BsListColumnsReverse className="icon" size={55} />,
        text: "Empresas por edificio",
      },
      { path: "/listadovetos", icon: <MdDoNotDisturbAlt className="icon" size={55} />, text: "Vetos por edificio" },
      { path: "/categorias", icon: <MdOutlineCategory className="icon" size={55} />, text: "Análisis Categorías" },
    ];
  };

  const getHerramientasComerciales = () => {
    const herramientas = [];

    if (rol === ROLES.COMERCIAL) {
      herramientas.push(
        { path: "/admin/online-cotizador", icon: <BsFileSpreadsheet className="icon" size={55} />, text: "Cotizador" },
        { path: "/admin/mis-ventas", icon: <TbUserDollar className="icon" size={55} />, text: "CRM & Ventas" },
        { path: "/admin/ventas-detalle", icon: <TfiMoney className="icon" size={55} />, text: "Ventas" },
        { path: "/admin/ventas-region", icon: <GiSouthAmerica className="icon" size={55} />, text: "Región" },
        {
          path: "/admin/cargar-intermediario",
          icon: <LuClipboardList className="icon" size={55} />,
          text: "Cargar Agencia",
        },
        { path: "/admin/cargar-cliente", icon: <LuClipboardList className="icon" size={55} />, text: "Cargar Cliente" },
        {
          path: "/admin/cargar-oportunidad",
          icon: <LuClipboardList className="icon" size={55} />,
          text: "Cargar Oportunidad",
        },
        { path: "/admin/usuarios", icon: <HiOutlineUsers className="icon" size={55} />, text: "Usuarios" },
        {
          path: "/admin/clientes-cartera",
          icon: <PiListPlusLight className="icon" size={55} />,
          text: "Cartera Clientes",
        }
      );
      if (pais === "Chile" || pais === "Perú") {
        const misComisionesIndex = herramientas.findIndex((h) => h.text === "Mis Comisiones");
        if (misComisionesIndex === -1) {
          herramientas.splice(2, 0, {
            path: "/admin/mis-facturas",
            icon: <FaFileInvoiceDollar className="icon" size={55} />,
            text: "Mis Comisiones",
          });
        }
      }
    } else if (rol === ROLES.GERENCIA) {
      herramientas.push(
        { path: "/admin/online-cotizador", icon: <BsFileSpreadsheet className="icon" size={55} />, text: "Cotizador" },
        {
          path: "/admin/ventas-proyeccion",
          icon: <GiChart className="icon" size={55} />,
          text: "Proyección Comercial",
        },
        { path: "/admin/mis-ventas", icon: <TbUserDollar className="icon" size={55} />, text: "Ventas por Ejecutivo" },
        { path: "/admin/ventas-detalle", icon: <TfiMoney className="icon" size={55} />, text: "Ventas" },
        { path: "/admin/ventas-region", icon: <GiSouthAmerica className="icon" size={55} />, text: "Región" },
        {
          path: "/admin/cargar-intermediario",
          icon: <LuClipboardList className="icon" size={55} />,
          text: "Cargar Agencia",
        },
        { path: "/admin/cargar-cliente", icon: <LuClipboardList className="icon" size={55} />, text: "Cargar Cliente" },
        {
          path: "/admin/cargar-oportunidad",
          icon: <LuClipboardList className="icon" size={55} />,
          text: "Cargar Oportunidad",
        },
        { path: "/admin/usuarios", icon: <HiOutlineUsers className="icon" size={55} />, text: "Usuarios" },
        {
          path: "/admin/ventas-facturas",
          icon: <LiaFileInvoiceDollarSolid className="icon" size={55} />,
          text: "Facturación",
        },
        {
          path: "/admin/informes-comision",
          icon: <LuClipboardList className="icon" size={55} />,
          text: "Comisión Edificios",
        },
        { path: "/admin/edificios", icon: <BsBuildingCheck className="icon" size={55} />, text: "Edificios" },
        {
          path: "/admin/clientes-cartera",
          icon: <PiListPlusLight className="icon" size={55} />,
          text: "Cartera Clientes",
        }
      );
      if (pais === "Chile" || pais === "Perú") {
        const comisionIndex = herramientas.findIndex((h) => h.text === "Comisión Comercial");
        if (comisionIndex === -1) {
          herramientas.splice(2, 0, {
            path: "/admin/mis-facturas",
            icon: <TbUserDollar className="icon" size={55} />,
            text: "Comisión Comercial",
          });
        }
      }
    } else if (rol === ROLES.SUPERADMIN) {
      herramientas.push(
        {
          path: "/admin/ventas-proyeccion",
          icon: <GiChart className="icon" size={55} />,
          text: "Proyección Comercial",
        },
        { path: "/admin/mis-ventas", icon: <TbUserDollar className="icon" size={55} />, text: "CRM & Ventas" },
        {
          path: "/admin/mis-facturas",
          icon: <FaFileInvoiceDollar className="icon" size={55} />,
          text: "Mis Comisiones",
        },
        { path: "/admin/ventas-detalle", icon: <TfiMoney className="icon" size={55} />, text: "Ventas" },
        { path: "/admin/ventas-region", icon: <GiSouthAmerica className="icon" size={55} />, text: "Región" },
        {
          path: "/admin/cargar-intermediario",
          icon: <LuClipboardList className="icon" size={55} />,
          text: "Cargar Agencia",
        },
        { path: "/admin/cargar-cliente", icon: <LuClipboardList className="icon" size={55} />, text: "Cargar Cliente" },
        {
          path: "/admin/cargar-opportunidad",
          icon: <LuClipboardList className="icon" size={55} />,
          text: "Cargar Oportunidad",
        },
        { path: "/admin/usuarios", icon: <HiOutlineUsers className="icon" size={55} />, text: "Usuarios" },
        {
          path: "/admin/ventas-facturas",
          icon: <LiaFileInvoiceDollarSolid className="icon" size={55} />,
          text: "Facturación",
        },
        {
          path: "/admin/informes-comision",
          icon: <LuClipboardList className="icon" size={55} />,
          text: "Comisión Edificios",
        },
        { path: "/admin/edificios", icon: <BsBuildingCheck className="icon" size={55} />, text: "Edificios" },
        {
          path: "/admin/clientes-cartera",
          icon: <PiListPlusLight className="icon" size={55} />,
          text: "Cartera Clientes",
        }
      );
    } else if (rol === ROLES.EDIFICIO) {
      herramientas.push({
        path: "/admin/informes-comision",
        icon: <LuClipboardList className="icon" size={55} />,
        text: "Comisión Edificios",
      });
    } else if (rol === ROLES.AGENCIA) {
      herramientas.push({
        path: "/admin/online-cotizador",
        icon: <BsFileSpreadsheet className="icon" size={55} />,
        text: "Cotizador",
      });
    } else if (rol === ROLES.ADMINISTRATIVO) {
      herramientas.push(
        { path: "/admin/ventas-detalle", icon: <TfiMoney className="icon" size={55} />, text: "Ventas" },
        {
          path: "/admin/ventas-facturas",
          icon: <LiaFileInvoiceDollarSolid className="icon" size={55} />,
          text: "Facturación",
        },
        {
          path: "/admin/informes-comision",
          icon: <LuClipboardList className="icon" size={55} />,
          text: "Comisión Edificios",
        },
        { path: "/admin/usuarios", icon: <HiOutlineUsers className="icon" size={55} />, text: "Usuarios" },
        { path: "/admin/edificios", icon: <BsBuildingCheck className="icon" size={55} />, text: "Edificios" },
        {
          path: "/admin/clientes-cartera",
          icon: <PiListPlusLight className="icon" size={55} />,
          text: "Cartera Clientes",
        }
      );
      if (pais === "Chile") {
        herramientas.splice(2, 0, {
          path: "/admin/mis-facturas",
          icon: <TbUserDollar className="icon" size={55} />,
          text: "Comisión Ejecutivos",
        });
      }
    } else if (rol === ROLES.BOARD) {
      herramientas.push({
        path: "/admin/ventas-proyeccion",
        icon: <GiChart className="icon" size={55} />,
        text: "Proyección Comercial",
      });
    }

    // Filtrar cualquier herramienta que no comience con '/admin/' para garantizar que no esté en "COMERCIALES"
    return herramientas.filter((herramienta) => herramienta.path.startsWith("/admin/"));
  };

  return (
    <div className="Admin">
      <div className="logo-container mb-4">
        <img src={logo} alt={`${pais} Logo`} className="logo" />
      </div>
      <div className="welcome-text">
        <p>Hola {nombreUsuario} :)</p>
        <h2>PANEL DE HERRAMIENTAS</h2>
      </div>
      <div className="sections-container">
        {rol !== ROLES.BOARD && rol !== ROLES.EDIFICIO && (
          <div className="section-wrapper">{renderSection("UTILITARIAS", getHerramientasUtilitarias())}</div>
        )}
        <div className="section-wrapper">{renderSection("COMERCIALES", getHerramientasComerciales())}</div>
      </div>
      <div className="col-12 mt-5">
        <button className="btn btn-primary mb-3" type="submit" onClick={logout}>
          LOGOUT
        </button>
      </div>
    </div>
  );
};
