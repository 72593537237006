/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable unicorn/prefer-module */
import React, { useMemo } from "react";
import { useAuthContext } from "../../context/auth";
import banderaChile from "../../img/flag_chile.png";
import banderaPeru from "../../img/flag_peru.png";
import banderaUruguay from "../../img/flag_uruguay.png";
import fondoChile from "../../img/fondowecastbyreimpulso.png";
import logoUruguay from "../../img/screenmedia-logo-transparente.png";
import logoPeru from "../../img/visiona-logo-transparente.png";
import logoChile from "../../img/wecastbyreimpulso.svg";

import "./stylesHome.scss";

export const Home = () => {
  const { pais } = useAuthContext();

  const logoHome = useMemo(() => {
    switch (pais) {
      case "Chile":
        return logoChile;
      case "Perú":
        return logoPeru;
      case "Uruguay":
        return logoUruguay;
      default:
        return logoPeru;
    }
  }, [pais]);

  const mail = useMemo(
    () =>
      ({
        Chile: "hola@wecast.cl",
        Perú: "hola@visiona.pe",
        Uruguay: "hola@screenmedia.com.uy",
        Region: "info@adigitalholding.com",
      })[pais] || "hola@visiona.pe",
    [pais]
  );

  const uruguayUrl = "https://reimpulso.uy";

  if (pais === "Region") {
    return (
      <main className="red-gradient">
        <div className="centered-content">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh",
              minHeight: "70vh",
            }}
          >
            <div style={{ textAlign: "center", width: "100%" }}>
              <div className="content">
                <h1 className="logoHome">
                  <span className="bold">Adigital</span>Holding
                </h1>
                <div className="subcontent">
                  <div className="phrase">Digital signage en edificios corporativos</div>
                  <div className="countries-container">
                    <div className="countries-names flex justify-center items-center">
                      <h3 className="country-1">Perú</h3>
                      <h3 className="divider-1">|</h3>
                      <h3 className="country-2">Uruguay</h3>
                      <h3 className="divider-2">|</h3>
                      <h3 className="country-3">Chile</h3>
                    </div>
                    <div className="countries-flags flex justify-center items-center">
                      <img className="flag country-1" src={banderaPeru} alt="Bandera de Perú" />
                      <span className="divider-1" />
                      <img className="flag country-2" src={banderaUruguay} alt="Bandera de Uruguay" />
                      <span className="divider-2" />
                      <img className="flag country-3" src={banderaChile} alt="Bandera de Chile" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }

  if (pais === "Chile") {
    return (
      <main className="Home" style={{ backgroundImage: `url(${fondoChile})` }}>
        <div className="container">
          <div className="container-auxiliar">
            <img src={logoHome} alt="Logo" className="logoHome" />
            <p className="texto-titulo">¡Estamos trabajando en algo nuevo!</p>
            <p className="texto">
              Construimos nuestro sitio web para ofrecerte la mejor experiencia y mostrar nuestras soluciones digitales.
              Mientras tanto te invitamos a explorar nuestros proyectos en Uruguay.
            </p>
            <p className="texto">
              Más información:{" "}
              <a href={uruguayUrl} style={{ fontSize: "1.5rem", color: "#2ce67c", textDecoration: "none" }}>
                reimpulso.uy
              </a>
            </p>
            <p className="correo">
              Contáctanos:{" "}
              <a href={`mailto:${mail}`} style={{ color: "white", textDecoration: "none" }}>
                {mail}
              </a>
            </p>
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="Home">
      <div className="container">
        <div style={{ zIndex: "1", backdropFilter: "blur(1px)" }}>
          <img src={logoHome} alt="Logo" />
          <p style={{ fontSize: "3.5rem", width: "100%", backdropFilter: "blur(1px)", marginTop: "4rem" }}>
            ¡Estamos trabajando en algo nuevo!
          </p>
          <p style={{ fontSize: "2rem", backdropFilter: "blur(1px)", marginTop: "2rem" }}>
            Mientras, puedes contactarnos aquí:{" "}
            <a href={`mailto:${mail}`} style={{ color: "white", textDecoration: "none", fontSize: "2rem" }}>
              {mail}
            </a>
          </p>
        </div>
      </div>
    </main>
  );
};
