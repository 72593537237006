/* eslint-disable unicorn/prefer-module */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import cx from "classix";
import { Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos } from "../../utils";
import "./stylesCategoriaAutomotriz.scss";

export const Automotriz = ({ forceSubmit, edificiosPreseleccionados }) => {
  const [edificios, setEdificios] = useState([]);
  const [checkAll, setCheckAll] = useState(true); // Estado inicial para seleccionar todos
  const [checkedBuildings, setCheckedBuildings] = useState({});
  const [totalEstacionamientosSeleccionados, setTotalEstacionamientosSeleccionados] = useState(0);
  const [cantidadEdificiosSeleccionados, setCantidadEdificiosSeleccionados] = useState(0);
  const [porcentajeGamaMedia, setPorcentajeGamaMedia] = useState(70);
  const [recambioGamaMedia, setRecambioGamaMedia] = useState(4);
  const [recambioGamaAlta, setRecambioGamaAlta] = useState(2);
  const [precioGamaMedia, setPrecioGamaMedia] = useState(20000); // Estado inicial para precio gama media
  const [precioGamaAlta, setPrecioGamaAlta] = useState(60000); // Estado inicial para precio gama alta
  const { pais } = useAuthContext();

  const localString = (pais) =>
    ({
      Chile: "es-CL",
      Perú: "es-PE",
      Uruguay: "es-UY",
    })[pais] || "es-UY";

  const urlsPorPais = {
    Chile: "https://www.wecast.cl/listadoempresas",
    Perú: "https://visiona.pe/listadoempresas",
    Uruguay: "https://screenmedia.com.uy",
  };

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  const urlListadoEmpresas = urlsPorPais[pais];

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      setEdificios(newEdificios.sort((a, b) => a.edificio.localeCompare(b.edificio)));

      // Inicializar todos los edificios como seleccionados
      const initialCheckedBuildings = {};
      newEdificios.forEach(({ id }) => {
        initialCheckedBuildings[id] = true;
      });
      setCheckedBuildings(initialCheckedBuildings);

      // Actualizar la cantidad y total de estacionamientos seleccionados inicialmente considerando la ocupación
      setCantidadEdificiosSeleccionados(newEdificios.length);

      const totalEstacionamientos = newEdificios.reduce(
        (acc, { estacionamientos, personas, estacionamientos_ocupacion }) => {
          const estacionamientosCalculados =
            estacionamientos !== null && estacionamientos !== undefined
              ? estacionamientos
              : Math.round(personas * estacionamientosEstimados(newEdificios));

          const ocupacionFactor =
            estacionamientos_ocupacion !== null && estacionamientos_ocupacion !== undefined
              ? estacionamientos_ocupacion / 100
              : 1; // Si no hay ocupación definida, consideramos el 100%

          return acc + Math.round(estacionamientosCalculados * ocupacionFactor);
        },
        0
      );

      setTotalEstacionamientosSeleccionados(totalEstacionamientos);
    };

    initialize();
  }, [pais]);

  // Calcular espacios de estacionamiento estimados según el número de personas
  const estacionamientosEstimados = useCallback((edificios) => {
    let totalEstacionamientos = 0;
    let totalPersonas = 0;

    // Sumar estacionamientos y personas para los edificios que tienen datos
    edificios.forEach(({ estacionamientos, personas }) => {
      if (estacionamientos && personas) {
        totalEstacionamientos += estacionamientos;
        totalPersonas += personas;
      }
    });

    // Calcular el promedio de estacionamientos por persona
    const promedioEstacionamientosPorPersona = totalPersonas ? totalEstacionamientos / totalPersonas : 0;

    return promedioEstacionamientosPorPersona;
  }, []);

  const toggleCheck = useCallback((id) => {
    setCheckedBuildings((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  }, []);

  const toggleCheckAll = useCallback(() => {
    if (!checkAll) {
      const allChecked = {};
      edificios.forEach(({ id }) => {
        allChecked[id] = true;
      });
      setCheckedBuildings(allChecked);
    } else {
      setCheckedBuildings({});
    }
    setCheckAll((prev) => !prev);
  }, [checkAll, edificios]);

  // Efecto para recalcular la suma de estacionamientos y cantidad de edificios seleccionados
  useEffect(() => {
    const calcularEstadisticasSeleccionadas = () => {
      let totalEstacionamientos = 0;
      let cantidadEdificios = 0;

      edificios.forEach(({ id, estacionamientos, personas, estacionamientos_ocupacion }) => {
        if (checkedBuildings[id]) {
          cantidadEdificios++;
          const estacionamientosCalculados =
            estacionamientos !== null && estacionamientos !== undefined
              ? estacionamientos
              : Math.round(personas * estacionamientosEstimados(edificios));

          const ocupacionFactor =
            estacionamientos_ocupacion !== null && estacionamientos_ocupacion !== undefined
              ? estacionamientos_ocupacion / 100
              : 1; // Si no hay ocupación definida, consideramos el 100%

          totalEstacionamientos += Math.round(estacionamientosCalculados * ocupacionFactor);
        }
      });

      setCantidadEdificiosSeleccionados(cantidadEdificios);
      setTotalEstacionamientosSeleccionados(totalEstacionamientos);
    };

    calcularEstadisticasSeleccionadas();
  }, [checkedBuildings, edificios, estacionamientosEstimados]);

  // Función de cálculo para autos de gama alta y gama media
  const calcularGamaAutos = (totalEstacionamientos) => {
    const gamaMedia = Math.round(totalEstacionamientos * (porcentajeGamaMedia / 100)); // Usa porcentaje de gama media
    const gamaAlta = Math.round(totalEstacionamientos * ((100 - porcentajeGamaMedia) / 100));
    return { gamaAlta, gamaMedia };
  };

  const { gamaAlta, gamaMedia } = calcularGamaAutos(totalEstacionamientosSeleccionados);

  if (edificios.length === 0) return <Loader />;

  const cantidadGamaMedia = gamaMedia / recambioGamaMedia;
  const cantidadGamaAlta = gamaAlta / recambioGamaAlta;
  const cantidadGamaMediaMensual = cantidadGamaMedia / 12;
  const cantidadGamaAltaMensual = cantidadGamaAlta / 12;
  const cantidadGamaMediaPersonasMes = cantidadGamaMedia / 12;
  const cantidadGamaAltaPersonasMes = cantidadGamaAlta / 12;
  const cantidadPersonasTotal = cantidadGamaMediaPersonasMes + cantidadGamaAltaPersonasMes;
  const mercadoGamaMedia = cantidadGamaMedia * precioGamaMedia;
  const mercadoGamaAlta = cantidadGamaAlta * precioGamaAlta;
  const mercadoGamaTotal = mercadoGamaMedia + mercadoGamaAlta;

  const preciosGamaMedia = [20000, 30000, 40000, 50000];
  const preciosGamaAlta = [60000, 70000, 80000, 90000];

  return (
    <div className="automotriz-table">
      <div className="logo-container">
        <img src={logo} alt={`${pais} Logo`} className="logo" />
      </div>
      <div style={{ textAlign: "left", maxWidth: "70%", margin: "0 auto", marginTop: "1rem" }}>
        <p>Por favor:</p>
        <ol>
          <li>SELECCIONA EL INVENTARIO (todos los edificios son seleccionados por defecto)</li>
          <li>DEFINIR:</li>
          <ul>
            <li>PORCENTAJE GAMA MEDIA (el resto serán gama alta)</li>
            <li>HÁBITO DE RECAMBIO SEGÚN LA CATEGORÍA DEL AUTO (¿cada cuántos años renuevan la compra?)</li>
            <li>PRECIO PROMEDIO SEGÚN CATEGORÍA</li>
          </ul>
        </ol>
      </div>
      <hr style={{ width: "80%", margin: "0 auto", marginTop: "1rem", marginBottom: "1rem" }} />
      <div style={{ maxWidth: "70%", margin: "0 auto" }}>
        <table className="table table-bordered table-hover mx-auto">
          <tbody>
            <tr>
              <td style={{ textAlign: "left" }}>EDIFICIOS SELECCIONADOS:</td>
              <td style={{ textAlign: "right" }}>{cantidadEdificiosSeleccionados}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>CANTIDAD DE ESTACIONAMIENTOS (según % de ocupación):</td>
              <td style={{ textAlign: "right" }}>
                {totalEstacionamientosSeleccionados.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>ASIGNAR % CATEGORÍA GAMA MEDIA:</td>
              <td style={{ textAlign: "right" }}>
                <div className="d-flex justify-content-end">
                  <select
                    id="selectGamaMedia"
                    value={porcentajeGamaMedia}
                    onChange={(e) => setPorcentajeGamaMedia(Number(e.target.value))}
                    className="form-select form-select-sm w-auto"
                    style={{ maxWidth: "120px", textAlign: "center", textAlignLast: "center" }}
                  >
                    <option value={50}>50%</option>
                    <option value={60}>60%</option>
                    <option value={70}>70%</option>
                    <option value={80}>80%</option>
                    <option value={90}>90%</option>
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr style={{ width: "80%", margin: "0 auto", marginTop: "1rem", marginBottom: "1rem" }} />
      <div className="table-container">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th width="50%" scope="col" className="text-start">
                CONCEPTO
              </th>
              <th width="25%" scope="col" className="text-center">
                GAMA MEDIA
              </th>
              <th width="25%" scope="col" className="text-center">
                GAMA ALTA
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="text-start">CANTIDAD AUTOS</th>
              <th className="text-center">
                {gamaMedia.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
              <th className="text-center">
                {gamaAlta.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
            </tr>
            <tr>
              <th className="text-start">RECAMBIO: HÁBITO PROMEDIO</th>
              <th className="text-center">
                <div className="d-flex justify-content-center">
                  <select
                    id="selectRecambioGamaMedia"
                    value={recambioGamaMedia}
                    onChange={(e) => setRecambioGamaMedia(Number(e.target.value))}
                    className="form-select form-select-sm w-auto"
                    style={{ maxWidth: "120px", textAlign: "center", textAlignLast: "center" }}
                  >
                    <option value={2}>2 AÑOS</option>
                    <option value={3}>3 AÑOS</option>
                    <option value={4}>4 AÑOS</option>
                    <option value={5}>5 AÑOS</option>
                    <option value={6}>6 AÑOS</option>
                  </select>
                </div>
              </th>
              <th className="text-center">
                <div className="d-flex justify-content-center">
                  <select
                    id="selectRecambioGamaAlta"
                    value={recambioGamaAlta}
                    onChange={(e) => setRecambioGamaAlta(Number(e.target.value))}
                    className="form-select form-select-sm w-auto"
                    style={{ maxWidth: "120px", textAlign: "center", textAlignLast: "center" }}
                  >
                    <option value={1}>1 AÑO</option>
                    <option value={2}>2 AÑOS</option>
                    <option value={3}>3 AÑOS</option>
                  </select>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-start">RECAMBIO: CANTIDAD AUTOS x AÑO</th>
              <th className="text-center">
                {cantidadGamaMedia.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
              <th className="text-center">
                {cantidadGamaAlta.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
            </tr>
            <tr>
              <th className="text-start">PRECIO: PROMEDIO MERCADO SEGÚN CATEGORÍA</th>
              <th className="text-center">
                <div className="d-flex justify-content-center">
                  <select
                    id="selectPrecioGamaMedia"
                    value={precioGamaMedia}
                    onChange={(e) => setPrecioGamaMedia(Number(e.target.value))}
                    className="form-select form-select-sm w-auto"
                    style={{ maxWidth: "120px", textAlign: "center", textAlignLast: "center" }}
                  >
                    {preciosGamaMedia.map((precio) => (
                      <option key={precio} value={precio}>
                        US${" "}
                        {precio.toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th className="text-center">
                <div className="d-flex justify-content-center">
                  <select
                    id="selectPrecioGamaAlta"
                    value={precioGamaAlta}
                    onChange={(e) => setPrecioGamaAlta(Number(e.target.value))}
                    className="form-select form-select-sm w-auto"
                    style={{ maxWidth: "120px", textAlign: "center", textAlignLast: "center" }}
                  >
                    {preciosGamaAlta.map((precio) => (
                      <option key={precio} value={precio}>
                        US${" "}
                        {precio.toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
            </tr>
            <tr>
              <th className="text-start">MERCADO POR CATEGORÍA ANUAL</th>
              <th className="text-center">
                US${" "}
                {mercadoGamaMedia.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
              <th className="text-center">
                US${" "}
                {mercadoGamaAlta.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
            </tr>
            <tr>
              <th className="text-start">MERCADO POR CATEGORÍA MENSUAL</th>
              <th className="text-center">
                US${" "}
                {(mercadoGamaMedia / 12).toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
              <th className="text-center">
                US${" "}
                {(mercadoGamaAlta / 12).toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
            </tr>
            <tr>
              <th className="text-start bg-danger text-white">MERCADO ANUAL TOTAL</th>
              <th className="text-center bg-danger text-white" colSpan={2}>
                US${" "}
                {mercadoGamaTotal.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <hr style={{ width: "80%", margin: "0 auto", marginTop: "1rem", marginBottom: "1rem" }} />

      <div
        style={{
          fontSize: "1.3rem",
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
        }}
      >
        <div style={{ textAlign: "center", maxWidth: "80%", margin: "0 auto", marginBottom: "1.5rem" }}>
          <p>EN LOS EDIFICIOS SELECCIONADOS;</p>
          <p>
            <strong className="highlight">
              {cantidadPersonasTotal.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </strong>{" "}
            PERSONAS COMPRAN VEHÍCULOS 0KM <strong>TODOS LOS MESES</strong>,
          </p>
          <p style={{ marginTop: "1.2rem" }}>
            <strong className="highlight">
              {cantidadGamaMediaMensual.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </strong>{" "}
            CORRESPONDEN A <strong>GAMA MEDIA</strong> y{" "}
            <strong className="highlight">
              {cantidadGamaAltaMensual.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </strong>{" "}
            CORRESPONDEN A <strong>GAMA ALTA</strong>.
          </p>
        </div>
      </div>
      <hr style={{ width: "95%", margin: "0 auto" }} />
      <div style={{ width: "90%", margin: "0 auto", marginTop: "1.5rem", marginBottom: "1rem" }}>
        <p>Y a este universo y mercado, debemos agregar:</p>
        <ol>
          <li>Visitantes que tienen autos</li>
          <li>
            Ejecutivos que trabajan en los edificios y tienen autos, pero no acceden a un lugar de estacionamiento
          </li>
          <li>
            Ejecutivos con autos de gama alta, pero tienen segundo e incluso tercer auto en su ámbito familiar que suele
            ser de gama media
          </li>
        </ol>
        <p>
          Y por último, destacar las oportunidades en el segmento B2B en aquellos edificios con empresas vinculadas a
          sectores con flotas de vehículos tales como Minería, Logística, Retail, Infraestructura, Renting, entre otros.
        </p>
        <p>
          Aquí puedes revisar las empresas en los edificios:{" "}
          <a href={urlListadoEmpresas} style={{ color: "#007bff", textDecoration: "none" }}>
            listado empresas por edificio
          </a>
        </p>
      </div>
      <hr style={{ width: "100%", margin: "0 auto", marginTop: "1.5rem", marginBottom: "1rem" }} />
      <h2 style={{ textAlign: "center", marginBottom: "1rem" }}>INVENTARIO</h2>
      <div className="inventory-container">
        <table className="table table-striped table-hover table-sm" style={{ width: "60%", margin: "0 auto" }}>
          <thead className="table-dark">
            <tr>
              <th scope="col" className="text-center" onClick={toggleCheckAll}>
                {checkAll ? (
                  <ImCheckboxChecked className="icon" size={18} />
                ) : (
                  <ImCheckboxUnchecked className="icon" size={18} />
                )}
              </th>
              <th scope="col" className="text-center">
                EDIFICIO
              </th>
              <th scope="col" className="text-center">
                UBICACIÓN
              </th>
              <th scope="col" className="text-center">
                ESTACIONAMIENTOS
              </th>
              <th scope="col" className="text-center">
                OCUPACIÓN
              </th>
            </tr>
          </thead>
          <tbody>
            {edificios.map(
              ({ created_at, edificio, id, estacionamientos, ubicacion, personas, estacionamientos_ocupacion }) => {
                // Calcular estacionamientos estimados si no está disponible
                const estacionamientosCalculados =
                  estacionamientos !== null && estacionamientos !== undefined
                    ? estacionamientos
                    : Math.round(personas * estacionamientosEstimados(edificios)); // Redondear al entero más cercano
                return (
                  <tr
                    key={created_at}
                    onClick={() => toggleCheck(id)}
                    className={cx(checkedBuildings[id] && "table-success")}
                  >
                    <td className="text-center">
                      {checkedBuildings[id] ? (
                        <ImCheckboxChecked className="icon" size={18} />
                      ) : (
                        <ImCheckboxUnchecked className="icon" size={18} />
                      )}
                    </td>
                    <td className="text-center">{edificio.toUpperCase()}</td>
                    <td className="text-center">{ubicacion}</td>
                    <td className="text-center">{estacionamientosCalculados}</td>
                    <td className="text-center">
                      {estacionamientos_ocupacion !== null && estacionamientos_ocupacion !== undefined
                        ? `${estacionamientos_ocupacion}%`
                        : "-"}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Usage example (parent component)
const App = () => <Automotriz forceSubmit={() => {}} edificiosPreseleccionados="" />;

export default App;
